<div class="editpatient--body newrx--body edit--drug erx--body" *ngIf="!RxTransferType">
    <div class="row content--heading">
        <div class="col-md-5 padding-0">
            <div class="row">
                <div class="col-md-10 ">
                    <div *ngIf="showRxTransferIn" class="content--heading__title "> Rx Transfer In
                    </div>
                    <div *ngIf="showRxTransferOut" class="content--heading__title "> Rx Transfer Out
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 exprx--common-block flex--one">
            <div class="box-default mb-2">
                <div class="eprx--block__header" *ngIf="showRxTransferIn || showRxTransferOut">
                    <div class="col">
                        <span class="eprx--header__heading">
                            Rx Transfer
                        </span>
                    </div>
                </div>
                <div class="eprx--block__header" *ngIf="showNewRxTrIN">
                    <div class="col">
                        <span class="eprx--header__heading">
                            Rx To Transfer IN For
                        </span>
                    </div>
                </div>
                <div class="eprx--block__content insurance--body">
                    <div class="col-sm-12">
                        <div class="row">
                            <div *ngIf="(showRxTransferIn || showRxTransferOut) && !(showNewRxTrIN || frmPatHis)"
                                class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <eprx-input [LabelText]="searchText" [RxSelectId]="'RxNum'"
                                    [AutoFocus]="focusOn === ((searchText==='Rx To Transfer IN')?2:3)"
                                    [PlaceHolder]="'Enter Rx To Transfer'" [ControlName]="'prescnumid'"
                                    [FormGroupName]="rxTransferFG" (TriggerOnEnterValue)="getRxDetails()"></eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="showNewRxTrIN || frmPatHis">
                                <label class="align-label"> Rx# </label>
                                <div class="label--data">{{rxNumber}}</div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center"
                                *ngIf="!showNewRxTrIN && hideRxdetail">
                                <label class="align-label"> Rx Status</label>
                                <div class="label--data color_red"
                                    *ngIf="(rxStatus === 'unbilled')">
                                    UNBILLED</div>
                                <div class="label--data color_green"
                                    *ngIf="(rxStatus ==='billed')">
                                    BILLED</div>
                                <div class="label--data color_dark_blue"
                                    *ngIf="rxStatus ==='filed'">
                                    Deferred</div>
                                <div class="label--data"
                                    *ngIf="rxStatus === 'transferred'">
                                    TRANSFERRED</div>
                            </div>
                            <div [ngClass]="!showNewRxTrIN && hideRxdetail ? 'col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right pull-right padding_top_20px' : 'col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right pull-right padding_top_20px'"
                                *ngIf="!(showNewRxTrIN || frmPatHis)">
                                <button class="hotkey_success" appShortcutKey [AltKey]="'l'"
                                    (click)="clearRxdata()"><span>L</span> Clear</button>
                                <button class="hotkey_success" appShortcutKey [AltKey]="'p'" (click)="openPatientHist()"
                                    (keydown.tab)="focusRotation($event)"><span>P</span> Pat.History</button>
                            </div>
                        </div>
                    </div>
                    <div class="show-transistion" [ngClass]="hideRxdetail ? 'hide-transistion' : 'show-transistion'">
                        <!-- <div class="white--space">

                        </div> -->
                        <hr>
                        <div class="col-sm-12  erx--body">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="box-default mb-2">
                                        <div class="eprx--block__header">
                                            <span class="eprx--header__heading">
                                                Rx Info
                                            </span>
                                        </div>
                                        <!-- <h5 class="erx--label--primary"> <b>Rx Info</b></h5> -->
                                        <div class="eprx--block__content insurance--body">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                                    *ngIf="showRxTransferOut">
                                                    <label>Last Refill#</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Refillno ?
                                                        rxTransferDet?.Rxtransfer?.Refillno
                                                        : "0" }}</div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                                    *ngIf="showRxTransferOut">
                                                    <label>Refill# to be transferred</label>
                                                    <div class="label--data" *ngIf="isFiledRx && RfOrRx === '0'">
                                                        {{ refillNosFrFile }}
                                                    </div>
                                                    <div class="label--data" *ngIf="!isFiledRx && RfOrRx === '0'">
                                                        <span *ngIf="rxTransferDet?.Rxtransfer?.Refillno !== rxTransferDet?.Rxtransfer?.Aref">
                                                            {{rxTransferDet?.Rxtransfer?.Refillno ?
                                                                rxTransferDet?.Rxtransfer?.Refillno + 1
                                                                : 0 + 1 }} </span>
                                                        <span *ngIf="rxTransferDet?.Rxtransfer?.Refillno === rxTransferDet?.Rxtransfer?.Aref">
                                                            {{rxTransferDet?.Rxtransfer?.Refillno ?
                                                                rxTransferDet?.Rxtransfer?.Refillno
                                                                : 0 }}</span>
                                                    </div>
                                                    <div class="label--data" *ngIf="RfOrRx === '1'">
                                                        <span
                                                            *ngIf="rxTransferDet?.Rxtransfer?.PartialFillsList ">
                                                            {{rxTransferDet?.Rxtransfer?.PartialFillsList  ?
                                                                rxTransferDet?.Rxtransfer?.PartialFillsList  : 0}}
                                                                </span>
                                                                <span *ngIf="rxTransferDet?.Rxtransfer?.PartialFillsList && rxTransferDet?.Rxtransfer?.RefillsList">, </span>
                                                                
                                                        <span
                                                            *ngIf="rxTransferDet?.Rxtransfer?.RefillsList ">
                                                            {{ rxTransferDet?.Rxtransfer?.RefillsList }}  </span>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12" *ngIf="showRxTransferOut">
                                                                                            <label class="align-label">Last Refill#</label>
                                                                                            <div class="label--data">{{rxTransferDet?.Rxtransfer?.Refillno  ? rxTransferDet?.Rxtransfer?.Refillno
                                                                                                 : "0" }}</div>
                                                                                        </div> -->
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                                    *ngIf="!showRxTransferOut">
                                                    <label class="align-label">Refill# </label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Refillno ?
                                                        rxTransferDet?.Rxtransfer?.Refillno
                                                        : 0 }}</div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label class="align-label">Refill(s) Left </label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Refillsleft &&  rxTransferDet?.Rxtransfer?.Refillsleft > 0 ?
                                                        rxTransferDet?.Rxtransfer?.Refillsleft
                                                        : 0 }}</div>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                                    <label class="align-label">Auth#</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Aref ?
                                                        rxTransferDet?.Rxtransfer?.Aref :
                                                        "0" }}</div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label>Disp Qty / Ord Qty </label>
                                                    <div class="label--data">{{(rxTransferDet?.Rxtransfer?.DispQty ?
                                                        (rxTransferDet?.Rxtransfer?.DispQty)
                                                        : 0)| number : '1.3-3' }} / {{(rxTransferDet?.Rxtransfer?.Qty ?
                                                        (rxTransferDet?.Rxtransfer?.Qty)
                                                        : 0)| number : '1.3-3' }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                                                    <label class="align-label">Days </label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Days ?
                                                        rxTransferDet?.Rxtransfer?.Days
                                                        : "0" }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                    <label *ngIf="showRxTransferOut">Total Qty remaining</label>
                                                    <label *ngIf="!showRxTransferOut">Total Qty Transferred IN</label>
                                                    <div class="label--data" *ngIf="showRxTransferOut">
                                                        {{(rxTransferDet?.Rxtransfer?.QtyRem && rxTransferDet?.Rxtransfer?.QtyRem > 0 ?
                                                        (rxTransferDet?.Rxtransfer?.QtyRem)
                                                        : 0 )| number : '1.3-3'}}
                                                    </div>
                                                    <div class="label--data" *ngIf="!showRxTransferOut">
                                                        {{(rxTransferDet?.Rxtransfer?.Qty ?
                                                        ((rxTransferDet?.Rxtransfer?.Aref + 1) *
                                                        (rxTransferDet?.Rxtransfer?.Qty))
                                                        : 0)| number : '1.3-3' }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="showRxTransferOut">
                                                    <label> Qty Being Transferred </label>
                                                    <div class="label--data" *ngIf="isFiledRx && !(qtyTranFrFille && rxTransferDet?.Rxtransfer?.TotalQty)">
                                                        {{(qtyTranFrFille ?
                                                        (qtyTranFrFille)
                                                        : rxTransferDet?.Rxtransfer?.TotalQty ? rxTransferDet?.Rxtransfer?.TotalQty : 0 )| number : '1.3-3'}}
                                                    </div>
                                    
                                                    <div class="label--data"
                                                            *ngIf="isFiledRx && qtyTranFrFille && RfOrRx === '1' ">
                                                             {{&nbsp; ((rxTransferDet?.Rxtransfer?.PartialFillQty  && RemRefQty ) ? (rxTransferDet?.Rxtransfer?.PartialFillQty  + RemRefQty ) : 0 )| number : '1.3-3'}}
                                                    </div>
                                                    <div class="label--data"
                                                        *ngIf="isFiledRx && qtyTranFrFille && RfOrRx === '0'  && (rxTransferDet?.Rxtransfer?.Refillno === rxTransferDet?.Rxtransfer?.Aref) ">
                                                        {{&nbsp; (qtyTranFrFille ?
                                                        (qtyTranFrFille)
                                                        : 0 )| number : '1.3-3'}}
                                                    </div>
                                                    <span *ngIf="!isFiledRx">
                                                        <div class="label--data"
                                                            *ngIf="rxTransferDet?.Rxtransfer?.PartialFillQty && RfOrRx === '1' && rxTransferDet?.Rxtransfer?.Refillno !== rxTransferDet?.Rxtransfer?.Aref">
                                                            {{&nbsp; ((rxTransferDet?.Rxtransfer?.PartialFillQty && (rxTransferDet?.Rxtransfer?.TotalRefillsQty)) ? (rxTransferDet?.Rxtransfer?.PartialFillQty + (rxTransferDet?.Rxtransfer?.TotalRefillsQty))
                                                          
                                                            : 0 )| number : '1.3-3'}}
                                                        </div>

                                                        <div class="label--data"
                                                            *ngIf="!rxTransferDet?.Rxtransfer?.PartialFillQty && RfOrRx === '1' && rxTransferDet?.Rxtransfer?.Refillno !== rxTransferDet?.Rxtransfer?.Aref">
                                                            {{(rxTransferDet?.Rxtransfer?.TotalQty ?
                                                            (rxTransferDet?.Rxtransfer?.TotalQty)
                                                            : 0 )| number : '1.3-3'}}
                                                        </div>

                                                        <div class="label--data"
                                                            *ngIf="RfOrRx === '0' && rxTransferDet?.Rxtransfer?.Refillno !== rxTransferDet?.Rxtransfer?.Aref">
                                                            {{(rxTransferDet?.Rxtransfer?.TotalQty ?
                                                            (rxTransferDet?.Rxtransfer?.TotalQty)
                                                            : 0)| number : '1.3-3' }}</div>

                                                        <div class="label--data" *ngIf="rxTransferDet?.Rxtransfer?.Refillno === rxTransferDet?.Rxtransfer?.Aref">
                                                            {{(rxTransferDet?.Rxtransfer?.FillRemainingQty ?
                                                            (rxTransferDet?.Rxtransfer?.FillRemainingQty)
                                                            : 0)| number : '1.3-3' }}</div>
                                                    </span>

                                                </div>
                                                <div class="col-12"
                                                    *ngIf="showRxTransferOut">
                                                    <label>Sig</label>
                                                    <div class="tranfer-out-sig-height">
                                                        {{rxTransferDet?.Rxtransfer?.SigDescription | uppercase : "--"
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="col-9"
                                                    *ngIf="!showRxTransferOut">
                                                    <label>Sig</label>
                                                    <div class="tranfer-out-sig-height">
                                                        {{rxTransferDet?.Rxtransfer?.SigDescription | uppercase : "--"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="margin_top_35px">

                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div class="row">
                                                        <div class="col-4" *ngIf="showRxTransferIn || showNewRxTrIN">
                                                            <eprx-input [LabelText]="'Original Rx#'" [PlaceHolder]="''"
                                                                [ControlName]="'OrgRxNum'"
                                                                [FormGroupName]="rxTransferFG" [MaxLength]="15"
                                                                [AutoFocus]="focusOn === 2" [IsRequired]="true"
                                                                [FormGroupInvalid]="formGroupInvalid"
                                                                [RxSelectId]="'OriginalRxN'"
                                                                [InputErrors]="OrgRxNum?.errors"
                                                                (keydown.esc)="clearRxdata()" [RxSelectId]="'OrgRxNum'"
                                                                [MaskPattern]="'0*'"
                                                                [ErrorDefs]="{required: 'Required'}"></eprx-input>
                                                        </div>

                                                        <div class="col-4" *ngIf="showRxTransferIn || showNewRxTrIN">
                                                            <eprx-date-picker [LabelText]="'Original Rx Date'"
                                                                [RxSelectId]="'OriginalRxDate'"
                                                                [ControlName]="'OrgRxDtTm'" [PlaceHolder]="''"
                                                                [IsRequired]="true" [FormGroupName]="rxTransferFG"
                                                                [IsRequired]="true"
                                                                [InputErrors]="OrgRxDtTm?.errors"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                [FormGroupInvalid]="formGroupInvalid">
                                                            </eprx-date-picker>
                                                        </div>
                                                        <div class="col-4" *ngIf="showRxTransferOut">
                                                            <eprx-date-picker [LabelText]="'Original Rx Date'"
                                                                [RxSelectId]="'OriginalRxDate'"
                                                                [ControlName]="'OrgRxDtTm'" [PlaceHolder]="''"
                                                                [IsRequired]="true" [FormGroupName]="rxTransferFG"
                                                                [IsDisabled]="true"
                                                                [IsRequired]="true" [InputErrors]="OrgRxDtTm?.errors"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                [FormGroupInvalid]="formGroupInvalid" [TabIndex]="-1">
                                                            </eprx-date-picker>
                                                        </div>
                                                        <div class="col-4" *ngIf="showRxTransferIn || showNewRxTrIN">
                                                            <eprx-date-picker [LabelText]="'Last Rx Fill Date'"
                                                                [RxSelectId]="'LastRxDate'" [IsRequired]="true"
                                                                [ControlName]="'OrgLastRxFillDtTm'" [PlaceHolder]="''"
                                                                [FormGroupName]="rxTransferFG"
                                                                [InputErrors]="OrgLastRxFillDtTm?.errors"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                [FormGroupInvalid]="formGroupInvalid">
                                                            </eprx-date-picker>
                                                        </div>
                                                        <div class="col-4" *ngIf="showRxTransferOut">
                                                            <eprx-date-picker [LabelText]="'Last Rx Fill Date'"
                                                                [RxSelectId]="'LastRxDate'" [IsRequired]="true"
                                                                [ControlName]="'OrgLastRxFillDtTm'" [PlaceHolder]="''"
                                                                [IsDisabled]="true" [FormGroupName]="rxTransferFG"
                                                                [InputErrors]="OrgLastRxFillDtTm?.errors"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                [FormGroupInvalid]="formGroupInvalid" [TabIndex]="-1">
                                                            </eprx-date-picker>
                                                        </div>
                                                        <div class="col-4" *ngIf="showRxTransferOut">
                                                            <eprx-date-picker [LabelText]="'Transfer Date'"
                                                                [RxSelectId]="'TransOutDate'" [IsRequired]="true"
                                                                [ControlName]="'TransferDtTm'" [PlaceHolder]="''"
                                                                [FormGroupName]="rxTransferFG"
                                                                [InputErrors]="TransferDtTm?.errors"
                                                                [AutoFocus]="focusOn === 3"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                [FormGroupInvalid]="formGroupInvalid">
                                                            </eprx-date-picker>
                                                        </div>
                                                        <div class="col-4">
                                                            <eprx-input [LabelText]="'Other Pharmacist Name'"
                                                                [RxSelectId]="'OthPharName'" [PlaceHolder]="''"
                                                                [ControlName]="'OrgPharmacistNm'"
                                                                [FormGroupName]="rxTransferFG" [IsRequired]="true"
                                                                [InputErrors]="OrgPharmacistNm?.errors"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                [FormGroupInvalid]="formGroupInvalid" [MaxLength]="30"
                                                                (TriggerChangeValue)="frmPatHis?.['Type'] === 'Out' ? patchOtherPharmcistNm($event) : ''">
                                                            </eprx-input>
                                                        </div>
                                                        <div class="col-4">
                                                            <eprx-select [LabelText]="'Pharmacist\'s Name(Your)'"
                                                                [ControlName]="'PharmacistId'" [IsRequired]="true"
                                                                [FormGroupName]="rxTransferFG" [PlaceHolder]="''"
                                                                [BindLabel2]="'PharmacistName'"
                                                                [BindLabel]="'PharmacistFullName'"
                                                                [BindValue]="'PharmacistId'"
                                                                [LabelForId]="'PharmacistId'"
                                                                [LabelTitle2]="'Initials'"
                                                                [LabelTitle1]="'NAME'"
                                                                [List]="PharmacyNames.pharmacistNames"
                                                                (TriggerSelectValue)="selectedValue($event)"
                                                                [InputErrors]="PharmacistId?.errors"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                [FormGroupInvalid]="formGroupInvalid"></eprx-select>
                                                        </div>
                                                        <div class="col-4" [formGroup]="rxTransferFG">
                                                            <label class="align-label padding_bottom_1px">
                                                                Remarks </label>
                                                            <textarea [rows]="2"
                                                                [cols]="15" class="text-area h-76 width_100per"
                                                                formControlName="Remarks"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div class=" box-default mb-2 ">
                                        <div class="eprx--block__header">
                                            <span class="eprx--header__heading">
                                                <div class="row">
                                                    <div class="col-md-9" *ngIf="showRxTransferIn || showNewRxTrIN">
                                                        <span class="eprx--header__heading">
                                                            Pharmacy Information (Xfer From)
                                                        </span>
                                                    </div>
                                                    <div class="col-md-9" *ngIf="showRxTransferOut">
                                                        <span class="eprx--header__heading">
                                                            Pharmacy Information (Xfer To)
                                                        </span>
                                                    </div>
                                                    <div class="col-md-3 text-right">
                                                        <button class="hotkey_success hotkey_primary--Override"
                                                            (click)="openAddPopUp()" title="Add" appShortcutKey
                                                            [AltKey]="'a'"><span>A</span>ADD</button>
                                                        <i *ngIf="hideFilterForm"
                                                            class="far fa-edit action text-white pull-right"
                                                            (click)="editPopUp()" title="Edit"></i>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>


                                        <div class="eprx--block__content insurance--body ">
                                            <div class="row ">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                                    *ngIf="hideRxdetail">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <eprx-select [LabelText]="'Select Pharmacy'"
                                                                [ControlName]="'XferPharmIdxId'"
                                                                [FormGroupName]="rxTransferFG"
                                                                [PlaceHolder]="'Search Pharmacy Name'"
                                                                [BindLabel]="'PharmacyName'"
                                                                [BindLabel2] = "'Phone1'"
                                                                [BindLabel3] = "'Phone2'"
                                                                [BindLabel4] = "'Fax'"
                                                                [BindLabel5]="'addresses'"
                                                                [hasBindLabel4] = true
                                                                [LabelTitle1]="'Pharmacy Name'"
                                                                [LabelTitle2]="'Phone#1'"
                                                                [LabelTitle3]="'Phone#2'"
                                                                [LabelTitle4]="'Fax#'"
                                                                [LabelTitle5]="'Address'"
                                                                [IsTitle]="true"
                                                                [BindValue]="'Id'"
                                                                [LabelForId]="'Id'"
                                                                [List]="PharmacyNames?.pharmNames"
                                                                (TriggerSelectValue)="getPharmcyDetails($event)"
                                                                [IsRequired]="true"
                                                                (keydown.tab)="focusOutFmPharm($event)"
                                                                [InputErrors]="XferPharmIdxId?.errors"
                                                                [ErrorDefs]="{required: 'Required'}"
                                                                [FormGroupInvalid]="formGroupInvalid"
                                                                [phoneLabel]="true"
                                                                [faxLabel]="true"
                                                                [addressLabel]="true"
                                                                [DropdownPosition]="'top'"
                                                                [MarkAsTouched]="rxTransferFG?.controls?.XferPharmIdxId?.touched"
                                                                ></eprx-select>
                                                        </div>

                                                        <div class="show-transistion col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                                            [ngClass]="hideFilterForm ? 'hide-transistion' : 'show-transistion'">
                                                            <hr>
                                                            <div class="col-sm-12">
                                                                <div class="row">
                                                                    <div class="col-3">
                                                                        <label class="align-label"> Address </label>
                                                                        <div class="label--data">
                                                                            {{DataResult?.TransferPharmacy ? DataResult?.TransferPharmacy?.AddressLine1 : (pharmcyDet?.TransferPharmacy?.AddressLine1
                                                                            ? pharmcyDet?.TransferPharmacy?.AddressLine1
                                                                            : "--")}}</div>
                                                                    </div>
                                                                    <div class="col-3">
                                                                        <label class="align-label"> State </label>
                                                                        <div class="label--data">
                                                                            {{DataResult?.TransferPharmacy ? DataResult?.TransferPharmacy?.StateName : (pharmcyDet?.TransferPharmacy?.StateName ?
                                                                            pharmcyDet?.TransferPharmacy?.StateName
                                                                            : "--")}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3">
                                                                        <label class="align-label"> City </label>
                                                                        <div class="label--data">
                                                                            {{DataResult?.TransferPharmacy ? DataResult?.TransferPharmacy?.CityName : (pharmcyDet?.TransferPharmacy?.CityName ?
                                                                            pharmcyDet?.TransferPharmacy?.CityName
                                                                            : "--")}}</div>
                                                                    </div>
                                                                    <div class="col-3">
                                                                        <label class="align-label"> Zip </label>
                                                                        <div class="label--data">
                                                                            {{DataResult?.TransferPharmacy ? DataResult?.TransferPharmacy?.ZipCode : (pharmcyDet?.TransferPharmacy?.ZipCode ?
                                                                            pharmcyDet?.TransferPharmacy?.ZipCode
                                                                            : "--")}}</div>
                                                                    </div>
                                                                    <div class="col-3">
                                                                        <label class="align-label"> Phone#1 </label>
                                                                        <div class="label--data">
                                                                            {{DataResult?.TransferPharmacy ? (DataResult?.TransferPharmacy?.Phone1 ? (DataResult?.TransferPharmacy?.Phone1 | mask : "(000)000-0000") : "--") :
                                                                             (Phone1 ? (Phone1 | mask : "(000)000-0000") : "--")}}</div>
                                                                    </div>
                                                                    <div class="col-3">
                                                                        <label class="align-label"> Phone#2 </label>
                                                                        <div class="label--data">
                                                                            {{DataResult?.TransferPharmacy ? (DataResult?.TransferPharmacy?.Phone2 ? (DataResult?.TransferPharmacy?.Phone2 | mask : "(000)000-0000") : "--") :
                                                                            (Phone2 ? (Phone2 | mask : "(000)000-0000") : "--")}}</div>
                                                                    </div>
                                                                    <div class="col-3">
                                                                        <label class="align-label"> Fax# </label>
                                                                        <div class="label--data">{{DataResult?.TransferPharmacy ? (DataResult?.TransferPharmacy?.Fax ? (DataResult?.TransferPharmacy?.Fax | mask : "(000)000-0000"): "--") :
                                                                            (Fax ? (Fax | mask : "(000)000-0000") : "--")}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3">
                                                                        <label class="align-label"> Pharmacy DEA#
                                                                        </label>
                                                                        <div class="label--data">
                                                                            {{DataResult?.TransferPharmacy ? _formatsUtil.getDeaFormat(DataResult?.TransferPharmacy?.DEANum)  :
                                                                                (_formatsUtil.getDeaFormat(pharmcyDet?.TransferPharmacy?.DEANum) ?
                                                                                _formatsUtil.getDeaFormat(pharmcyDet?.TransferPharmacy?.DEANum)
                                                                            : "--")}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" box-default mb-2">
                                        <div class="eprx--block__header">
                                            <span class="eprx--header__heading">
                                                Patient
                                            </span>
                                        </div>
                                        <div class="eprx--block__content insurance--body">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" title={{rxTransferDet?.Rxtransfer?.PatientName|uppercase}}>
                                                    <label>Name</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.PatientName ?
                                                        rxTransferDet?.Rxtransfer?.PatientName
                                                        : "--" }}</div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <label>DOB</label>
                                                    <div class="label--data"> {{rxTransferDet?.Rxtransfer?.DateOfBirth ?
                                                        ( rxTransferDet?.Rxtransfer?.DateOfBirth|
                                                        date:'MM/dd/yyyy') : "--" }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                                    *ngIf="showRxTransferIn || showRxTransferOut">
                                                    <label>Insu Code</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.InsurerCode ?
                                                        rxTransferDet?.Rxtransfer?.InsurerCode
                                                        : "--" }}</div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="showNewRxTrIN">
                                                    <label>InsuId</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.InsurerCode ?
                                                        rxTransferDet?.Rxtransfer?.InsurerCode
                                                        : "--" }}</div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <label>Gender</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Gender ?
                                                        rxTransferDet?.Rxtransfer?.Gender
                                                        : "--" }}</div>
                                                </div>
                                                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="!showNewRxTrIN">
                                                                <label class="align-label"> Phone </label>
                                                                <div class="label--data">{{rxTransferDet?.Phone[0]?.Phone ? rxTransferDet?.Phone[0]?.Phone : "--" }}</div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="showNewRxTrIN">
                                                                <label class="align-label"> Phone </label>
                                                                <div class="label--data">{{newRxPhone ? newRxPhone : "--" }}</div>
                                                            </div> -->
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <label>Address</label>
                                                    <div class="label--data">
                                                        {{rxTransferDet?.Rxtransfer?.PatientAddress ?
                                                        rxTransferDet?.Rxtransfer?.PatientAddress
                                                        : "--" }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class=" box-default mb-2 ">
                                        <div class="eprx--block__header">
                                            <span class="eprx--header__heading">
                                                Prescriber
                                            </span>
                                        </div>
                                        <div class="eprx--block__content insurance--body">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" title ={{rxTransferDet?.Rxtransfer?.PrescriberName|uppercase}}>
                                                    <label>Name</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.PrescriberName
                                                        ? rxTransferDet?.Rxtransfer?.PrescriberName
                                                        : "--" }}</div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                    <label>DEA#</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.DEA ?
                                                        _formatsUtil.getDeaFormat(rxTransferDet?.Rxtransfer?.DEA) : "--"
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <label>NPI#</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.NPINum ?
                                                        rxTransferDet?.Rxtransfer?.NPINum
                                                        : "--" }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <label>Licence#</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Licencenumber
                                                        ? rxTransferDet?.Rxtransfer?.Licencenumber
                                                        : "--"}}</div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                                    *ngIf="!showNewRxTrIN">
                                                    <label class="align-label"> Phone# </label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Phone ? (rxTransferDet?.Rxtransfer?.Phone | mask : "(000)000-0000")
                                                        : "--" }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="showNewRxTrIN">
                                                    <label class="align-label"> Phone# </label>
                                                    <div class="label--data">{{newRxPhone ? (newRxPhone | mask : "(000)000-0000") : "--" }}</div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <label>Fax#</label>
                                                    <div class="label--data"> {{rxTransferDet?.Rxtransfer?.Fax ?
                                                        (rxTransferDet?.Rxtransfer?.Fax | mask  : "(000)000-0000") : "--"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class=" box-default mb-2 ">
                                        <div class="eprx--block__header">
                                            <span class="eprx--header__heading">
                                                Drug
                                            </span>
                                        </div>
                                        <div class="eprx--block__content insurance--body">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" title={{rxTransferDet?.Rxtransfer?.DrugDetail|uppercase}}>
                                                    <label>Name</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.DrugDetail ?
                                                        rxTransferDet?.Rxtransfer?.DrugDetail
                                                        : "--" }}</div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                    <label>NDC</label>
                                                    <div class="label--data">
                                                        {{this._formatsUtil.getNDCFormat(rxTransferDet?.Rxtransfer?.NDC)}}
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                                    *ngIf="showRxTransferIn || showRxTransferOut">
                                                    <label>Manufacturer</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Manufacturer ?
                                                        rxTransferDet?.Rxtransfer?.Manufacturer
                                                        : "--" }}</div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="showNewRxTrIN">
                                                    <label>Packet Size</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.PacketSize ?
                                                        (rxTransferDet?.Rxtransfer?.PacketSize | number : '1.3-3')
                                                        : "0.000" }}</div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="showNewRxTrIN">
                                                    <label class="align-label"> AWP </label>
                                                    <div class="label--data">{{(rxTransferDet?.Rxtransfer?.AWP ?
                                                        (rxTransferDet?.Rxtransfer?.AWP) : 0)| currency:
                                                        "$":"symbol":"1.2-2"
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                                    *ngIf="showRxTransferIn || showRxTransferOut">
                                                    <label>Packet Size</label>
                                                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.PacketSize ?
                                                        (rxTransferDet?.Rxtransfer?.PacketSize | number : '1.3-3')
                                                        : "0.000" }}</div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                                    *ngIf="showRxTransferIn || showRxTransferOut">
                                                    <label class="align-label"> AWP </label>
                                                    <div class="label--data">{{(rxTransferDet?.Rxtransfer?.AWP ?
                                                        (rxTransferDet?.Rxtransfer?.AWP) : 0)| currency:
                                                        "$":"symbol":"1.2-2"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="hideRxdetail">
                        <div class="col-md-5 padding-0"></div>
                        <div class="col-md-7 text-right pull-right"
                        *ngIf="(showRxTransferIn || showRxTransferOut) && !frmPatHis">
                        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferOut"><span>R</span>
                            Transfer-Out and Print
                        </button>
                        <button class="inactive" *ngIf="!this.rxTransferFG.valid && !showRxTransferOut"><span>R</span>
                            Transfer-In and Print
                        </button>
                        <button class="inactive" *ngIf="(!this.rxTransferFG.valid || !hasFaxNumber) && showRxTransferOut ">
                            <span>O</span>
                            Transfer-Out and Fax
                        </button>
                        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferOut">
                            <span>F</span> Transfer-Out<span *ngIf="showRxTransferIn">Transfer-In</span> </button>
                        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferIn"><span>I</span>
                            Transfer-In</button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'r'" (click)="RxTransferIn('print')"
                            *ngIf="this.rxTransferFG.valid && showRxTransferOut" [id]="'Print'"><span>R</span>
                            Transfer-Out and Print
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'r'" (click)="RxTransferIn('print')"
                            *ngIf="this.rxTransferFG.valid && !showRxTransferOut" [id]="'Print'"><span>R</span>
                            Transfer-In and Print
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'o'" (click)="updateFax()"
                            *ngIf="this.rxTransferFG.valid && showRxTransferOut && hasFaxNumber" [id]="'TransferOutFax'"><span>O</span>
                            Transfer-Out and Fax
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'f'" (click)="RxTransferIn('TransferOut')"
                            *ngIf="this.rxTransferFG.valid && showRxTransferOut" [id]="'Transfer'"><span>F</span>
                            Transfer-Out
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'i'" (click)="RxTransferIn()"
                            *ngIf="this.rxTransferFG.valid && showRxTransferIn" [id]="'Transfer'"><span>I</span>
                            Transfer-In
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="routeBackToPrevScreen('Normal')"
                            id="cancel"><span>C</span> Cancel</button>
                    </div>
                    <div class="col-md-7 text-right pull-right"
                        *ngIf="(showRxTransferIn || showRxTransferOut) && frmPatHis">
                        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferOut">
                            Transfer-Out and Print
                        </button>
                        <button class="inactive" *ngIf="!this.rxTransferFG.valid && !showRxTransferOut">
                            Transfer-In and Print
                        </button>
                        <button class="inactive" *ngIf="(!this.rxTransferFG.valid || !hasFaxNumber) && showRxTransferOut">
                            Transfer-Out and Fax
                        </button>
                        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferOut">
                            Transfer-Out<span *ngIf="showRxTransferIn">Transfer-In</span> </button>
                        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferIn">
                            Transfer-In</button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'r'" (click)="RxTransferIn('print')"
                            *ngIf="this.rxTransferFG.valid && showRxTransferOut" [id]="'Print'"><span>R</span>
                            Transfer-Out and Print
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'r'" (click)="RxTransferIn('print')"
                            *ngIf="this.rxTransferFG.valid && !showRxTransferOut" [id]="'Print'"><span>R</span>
                            Transfer-In and Print
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'o'" (click)="updateFax()"
                            *ngIf="this.rxTransferFG.valid && showRxTransferOut && hasFaxNumber" [id]="'TransferOutFax'"><span>O</span>
                            Transfer-Out and Fax
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'f'" (click)="RxTransferIn('TransferOut')"
                            *ngIf="this.rxTransferFG.valid && showRxTransferOut" [id]="'Transfer'"><span>F</span>
                            Transfer-Out
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'f'" (click)="RxTransferIn()"
                            *ngIf="this.rxTransferFG.valid && showRxTransferIn" [id]="'Transfer'"><span>F</span>
                            Transfer-In
                        </button>
                        <button class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="routeBackToPrevScreen('Normal')"
                            id="cancel"><span>C</span> Cancel</button>
                    </div>
    
                    </div>
                </div>
                
            </div>

        </div>
    </div>
    <span *ngIf="showRxTransferOut">
        <div class="eprx--block__content batchrefill grpTransOut-inprogress" *ngIf="grpTransOutRxs?.length && rxTransferDet && rxTransferDet.Rxtransfer">
            <b>Transfer-Out Rxs</b>
            <ul class="refill-inProgress">
                <li>({{currentTrOutRxIndex}} of {{grpTransOutRxs?.length}})</li>
                <li *ngFor="let trnsOutRx of grpTransOutRxs; let i = index"
                [ngClass]="trnsOutRx.trim() === (rxTransferDet.Rxtransfer.RxNum + '-' +
                rxTransferDet.Rxtransfer.Refillno +
                (frmPatHis?.FillNo ? '-' + frmPatHis?.FillNo : ''))
                        ? 'refill-prog--li inProgress--refill'
                        : 'refill-prog--li notinProgress--refill'"
            >
                <!-- <i
                    class="far fa-chevron-circle-right"
                    *ngIf="trnsOutRx === rxTransferDet.Rxtransfer.RxNum"
                ></i> -->
                {{ trnsOutRx }}
            </ul>
        </div>
    </span>
</div>


<div class="exprx--common-block  patient-edit--transfer-in eprx-transfer-req insurance--body my-0" *ngIf="RxTransferType === 'MarkTransferIn'" >

  
     <!--Steppers-->
    <div class="row" >
        <div class="col-12 row m-0">
            <div class="col-5 row m-0 pt-0" >
                <div class="col-12 text-center">
                    <div> <i  class="fas fa-check-circle"></i> Select Patient</div>
                    <div  class="stepper1_mark_as_transferIn eprx-eRx_transferReq-border-select">
                </div>
                </div>
            </div>
            <div class="col-2 text-center row pl-0 pt-0" >
                <div class="col-12 tex-center">
                    <div>  <i  class="fas fa-check-circle"></i> Select Transfer Type</div>
                    <div 
                    [ngClass]=" (rxTransferFG.value['XferPharmIdxId']) ? 'stepper2_mark_as_transferIn eprx-eRx_transferReq-border-select' : 'stepper2_mark_as_transferIn eprx-eRx_transferReq-border-unselect'">
                </div>
                </div>
            </div>
            <div class="col-5 text-center row" style="padding-top: 0px !important;">
                <div class="col-12 tex-center">
                    <div> <i  [ngClass]="rxTransferFG.value['XferPharmIdxId'] ? 'fas fa-check-circle' : 'fa-kit fa-circle-3-solid'"></i> Select Pharmacy</div>
                </div>
            </div>
        </div>
    </div>
    <!--Patient & Transfer Type & Pharmacy selection-->
    <div class="row mt-1">
        <div class="col-5">
            <app-patient-selection  [IsTransferType]="'markAsTransferIn'" [RxType]="" [IsfrmPatHis]="true" [SelectedPatientDetailsfrmRx]="selectedPatientDetails" ></app-patient-selection>
        </div>
        <div class="col-2 px-0">
            <app-transfer-type  [IsfrmPatHis]="true" [eRxModelFG]="rxTransferFG"></app-transfer-type>
        </div>
        <!--Pharmacy Selection-->
        <div class="col-5 pharmacy_dropdown exprx--common-block insurance--body">
            <div class="eprx--block__header row m-0">
                <div class="col-11 text-white  font-weight-bold">
                    Pharmacy Information (Xfer From)
                </div>
                <div class="col-1 pl-0 patient-edit--transfer-in text-right" *ngIf="hideFilterForm && rxTransferFG?.value['XferPharmIdxId']">
                        <i 
                    class="far fa-edit action text-white pull-right"
                    (click)="editPopUp()" title="Edit"></i>
                </div>
            </div>
            <div class="eprx--block__content pt-0 cards_height_transferIn">
                <div class="row row-space-transfer-in">
                    <div class="col-9 pr-0">
                        <eprx-select [LabelText]="''"
                            [ControlName]="'XferPharmIdxId'"
                            [FormGroupName]="rxTransferFG"
                            [PlaceHolder]="'Search Pharmacy Name'"
                            [BindLabel]="'PharmacyName'"
                            [BindLabel2] = "'Phone1'"
                            [BindLabel3] = "'Phone2'"
                            [BindLabel4] = "'Fax'"
                            [BindLabel5]="'addresses'"
                            [BindLabel6]="'NPI'"
                            [IsCardView]="true"
                            [hasBindLabel5] = true
                            [LabelTitle1]="'Pharmacy Name'"
                            [LabelTitle2]="'Phone1#'"
                            [LabelTitle3]="'Phone2#'"
                            [LabelTitle4]="'Fax#'"
                            [LabelTitle5]="'Address'"
                            [LabelTitle6]="'NPI#'"
                            [IsTitle]="true"
                            [BindValue]="'Id'"
                            [LabelForId]="'Id'"
                            [List]="PharmacyNames?.pharmNames"
                            (TriggerSelectValue)="getPharmcyDetails($event)"
                            [IsRequired]="true"
                            (keydown.tab)="focusOutFmPharm($event)"
                            [InputErrors]="XferPharmIdxId?.errors"
                            [ErrorDefs]="{required: 'Required'}"
                            [FormGroupInvalid]="formGroupInvalid"
                            [phoneLabel]="true"
                            [faxLabel]="true"
                            [addressLabel]="true"
                            [DropdownPosition]="'bottom'"
                            [MarkAsTouched]="rxTransferFG?.controls?.XferPharmIdxId?.touched"
                            ></eprx-select>
                    </div>
                    <div class="col-2 pl-0">
                        <button class="hotkey_success"
                        (click)="openAddPopUp()" title="Add" appShortcutKey
                        [AltKey]="'a'"><span>A</span>ADD</button>
                    </div>
                    <div class="col-1 pl-0 patient-edit--transfer-in">
                        <i class="fa-kit fa-pharmacy-icon" 
                        [ngClass]="rxTransferFG?.value['XferPharmIdxId'] ? 'color_green' : ''" ></i> 
                      </div>
                </div>
                <div class="row row-space-transfer-in">
                    <div class="col-12">
                        <label>{{pharmacyabels.pName}}</label> 
                        <div class="label--data"><span>{{pharmcyDet?.TransferPharmacy ?
                                (pharmcyDet?.TransferPharmacy?.PharmName) : msgConfig.EMPTY_Data}}</span></div>
                    </div>
                </div>
                <div class="row row-space-transfer-in">
                    <div class="col-4">
                        <label>{{pharmacyabels.Phone}}</label>
                        <div class="label--data"><span>{{this.Phone1 ?
                                (this._commonUtil.getPhoneFormat(this.Phone1)) : msgConfig.EMPTY_Data}}</span></div>
                    </div>
                    <div class="col-4">
                        <label>{{pharmacyabels.Fax}}</label>
                        <div class="label--data"><span>{{Fax ?
                                (this._commonUtil.getPhoneFormatForWrongFax(Fax)) : msgConfig.EMPTY_Data}}</span></div>
                    </div>
                    <div class="col-4">
                        <label>{{pharmacyabels.NPI}}</label>
                        <div class="label--data"><span>{{(pharmcyDet?.TransferPharmacy?.NPINum) ?
                                pharmcyDet?.TransferPharmacy?.NPINum : msgConfig.EMPTY_Data}}</span>
                        </div>
                    </div>
                </div>
                <div class="row row-space-transfer-in">
                    <div class="col-12">
                        <label>{{pharmacyabels.Address}}</label>
                        <div class="label--data"> <span>{{(pharmcyDet?.TransferPharmacy) ?
                                pharmcyDet?.TransferPharmacy?.FullAddress : msgConfig.EMPTY_Data}}</span></div>
                    </div>
                </div>
                <div class="row row-space-transfer-in">
                    <div class="col-6">
                        <eprx-input [LabelText]="'Rph Name'"
                        [RxSelectId]="'OthPharName'" [PlaceHolder]="''"
                        [ControlName]="'OrgPharmacistNm'"
                        [FormGroupName]="rxTransferFG"
                        [IsRequired]="true"
                        [ErrorDefs]="{required: 'Required'}"
                        [FormGroupInvalid]="formGroupInvalid"
                        [MaxLength]="30"></eprx-input>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <!--Rx Details-->
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        <div class="row">
            <div class="col-11">
                Rx Info
            </div>
            <div class="col-1" *ngIf="selectedpharmacyDetails">
                <i  (click)="openPharmacyedit()"
                class="far  fa-edit" title="Pharmacy Edit"></i>
            </div>
        </div>
    </div>
    <div
    class="eprx--block__content  mt-0" style="border-radius: 0px 0px 10px 10px;    height: 135px;">
    <div class="row"> 
        <div class="col-12"> 
        <div class="row">
            <div class="col-4 ">
              <div class="row">
                <div class="col-2" *ngIf="showNewRxTrIN || frmPatHis">
                    <label class="align-label"> Rx# </label>
                    <div class="label--data">{{rxNumber}}</div>
                </div>
                <div class="col-2">
                    <label class="align-label">Auth#</label>
                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Aref ?
                        rxTransferDet?.Rxtransfer?.Aref :
                        "0" }}</div>
                </div>
                <div class="col-2"> </div>
                <div class="col-6" title={{rxTransferDet?.Rxtransfer?.DrugDetail|uppercase}}>
                    <label>Drug Name</label>
                    <div class="label--data ellpise">{{rxTransferDet?.Rxtransfer?.DrugDetail ?
                        rxTransferDet?.Rxtransfer?.DrugDetail
                        : "--" }}</div>
                </div>
                <div class="col-6 row-space-transfer-in" title={{rxTransferDet?.Rxtransfer?.PrescriberName|uppercase}}>
                    <label>Prescriber Name</label>
                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.PrescriberName
                        ? rxTransferDet?.Rxtransfer?.PrescriberName
                        : "--" }}</div>
                </div>
                <div class="col-3 row-space-transfer-in">
                    <label>NPI#</label>
                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.NPINum ?
                        rxTransferDet?.Rxtransfer?.NPINum
                        : "--" }}
                    </div>
                </div>
                <div class="col-3 pr-0 row-space-transfer-in">
                    <label>DEA#</label>
                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.DEA ?
                        _formatsUtil.getDeaFormat(rxTransferDet?.Rxtransfer?.DEA) : "--"
                        }}
                    </div>
                </div>
              </div>
            </div>
            <div class="col-2">
                <div class="row">
                <div class="col-6 pr-0">
                    <label>NDC</label>
                    <div class="label--data">
                        {{this._formatsUtil.getNDCFormat(rxTransferDet?.Rxtransfer?.NDC)}}
                    </div>
                </div>
                <div class="col-6">
                    <label>Ord Qty </label>
                    <div class="label--data"> {{(rxTransferDet?.Rxtransfer?.Qty ?
                        (rxTransferDet?.Rxtransfer?.Qty)
                        : 0)| number : '1.3-3' }}
                    </div>
                </div>
                <div class="col-6 row-space-transfer-in">
                    <label>Licence#</label>
                    <div class="label--data">{{rxTransferDet?.Rxtransfer?.Licencenumber
                        ? rxTransferDet?.Rxtransfer?.Licencenumber
                        : "--"}}</div>
                </div>
                <div class="col-6 row-space-transfer-in">
                    <label class="align-label"> Phone1# </label>
                    <div class="label--data">
                        {{DataResult?.TransferPharmacy ? (DataResult?.TransferPharmacy?.Phone1 ? (DataResult?.TransferPharmacy?.Phone1 | mask : "(000)000-0000") : "--") :
                         (Phone1 ? (Phone1 | mask : "(000)000-0000") : "--")}}</div>
                </div>
            </div>
            </div>
            <div class="col-6">
                <div class="row">
                <div class="col-12 ">
                    <div class="row">

                    
                    <div class="col-2">
                        <label class="align-label">Days Supply </label>
                        <div class="label--data">{{rxTransferDet?.Rxtransfer?.Days ?
                            rxTransferDet?.Rxtransfer?.Days
                            : "0" }}
                        </div>
                    </div>
                    <div class="col-3 px-0">
                        <label>Total Qty Transferred IN</label>
                        <div class="label--data" >
                            {{(rxTransferDet?.Rxtransfer?.Qty ?
                            ((rxTransferDet?.Rxtransfer?.Aref + 1) *
                            (rxTransferDet?.Rxtransfer?.Qty))
                            : 0)| number : '1.3-3' }}
                        </div>
                    </div>
                    <div class="col-7 px-0">
                    <label>Sig</label>
                    <div>
                        {{rxTransferDet?.Rxtransfer?.SigDescription | uppercase : "--"
                        }}
                   </div>
                   </div>
                </div>
                </div>
                <div class="col-12 ">
                    <div class="row">
                    <div class="col-2 row-space-transfer-in">
                        <label class="align-label"> Fax# </label>
                        <div class="label--data">{{DataResult?.TransferPharmacy ? (DataResult?.TransferPharmacy?.Fax ? (DataResult?.TransferPharmacy?.Fax | mask : "(000)000-0000"): "--") :
                            (Fax ? (Fax | mask : "(000)000-0000") : "--")}}
                        </div>
                    </div>
                    <div class="col-3 pl-0 row-space-transfer-in" *ngIf="showRxTransferIn || showNewRxTrIN">
                        <eprx-input [LabelText]="'Original Rx#'" [PlaceHolder]="''"
                            [ControlName]="'OrgRxNum'"
                            [FormGroupName]="rxTransferFG" [MaxLength]="15"
                            [AutoFocus]="focusOn === 2" [IsRequired]="true"
                            [FormGroupInvalid]="formGroupInvalid"
                            [RxSelectId]="'OriginalRxN'"
                            [InputErrors]="OrgRxNum?.errors"
                            (keydown.esc)="clearRxdata()" [RxSelectId]="'OrgRxNum'"
                            [MaskPattern]="'0*'"
                            [ErrorDefs]="{required: 'Required'}"></eprx-input>
                    </div>
                    <div class="col-3 pl-0 row-space-transfer-in" *ngIf="showRxTransferIn || showNewRxTrIN">
                        <eprx-date-picker [LabelText]="'Last Rx Fill Date'"
                            [RxSelectId]="'LastRxDate'" [IsRequired]="true"
                            [ControlName]="'OrgLastRxFillDtTm'" [PlaceHolder]="''"
                            [FormGroupName]="rxTransferFG"
                            [InputErrors]="OrgLastRxFillDtTm?.errors"
                            [ErrorDefs]="{required: 'Required'}"
                            [FormGroupInvalid]="formGroupInvalid">
                        </eprx-date-picker>
                    </div>
                    <div class="col-3 pl-0 row-space-transfer-in" *ngIf="showRxTransferIn || showNewRxTrIN">
                        <eprx-date-picker [LabelText]="'Original Rx Date'"
                            [RxSelectId]="'OriginalRxDate'"
                            [ControlName]="'OrgRxDtTm'" [PlaceHolder]="''"
                            [IsRequired]="true" [FormGroupName]="rxTransferFG"
                            [IsRequired]="true"
                            [InputErrors]="OrgRxDtTm?.errors"
                            [ErrorDefs]="{required: 'Required'}"
                            [FormGroupInvalid]="formGroupInvalid">
                        </eprx-date-picker>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>

    </div> 
    </div>
    </div>
     <!---Your Pharmacy details & Notes-->
     <div class="row mt-1">
        <div class="col-5">
            <app-current-pharmacy  [LabelName]="'Your Pharmacy'"></app-current-pharmacy>
        </div>
        <div class="col-4 px-0">
            <app-single-notes [TransferIn]="'markasTransfer'" [RxType]="'nr'" (IsPopUpClosed)="transferSingleNotes($event)"  (TransferNotes)="transferSingleNotes($event)"  [IsNotPopup]="true" [MainCategory]="'Rx Annotation'" ></app-single-notes>
        </div>
        <div class="col-3 text-center" style="vertical-align: middle;line-height: 15;">
              <img src="/assets/Mark Transfer In.svg">
        </div>
    </div>
    <!--Buttons-->
    <div class="row" *ngIf="hideRxdetail">
        <div class="col-md-5 padding-0"></div>
        <div class="col-md-7 text-right pull-right"
        *ngIf="(showRxTransferIn || showRxTransferOut) && !frmPatHis">
        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferOut"><span>R</span>
            Transfer-Out and Print
        </button>
        <button class="inactive" *ngIf="!this.rxTransferFG.valid && !showRxTransferOut"><span>R</span>
            Transfer-In and Print
        </button>
        <button class="inactive" *ngIf="(!this.rxTransferFG.valid || !hasFaxNumber) && showRxTransferOut ">
            <span>O</span>
            Transfer-Out and Fax
        </button>
        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferOut">
            <span>F</span> Transfer-Out<span *ngIf="showRxTransferIn">Transfer-In</span> </button>
        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferIn"><span>I</span>
            Transfer-In</button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'r'" (click)="RxTransferIn('print')"
            *ngIf="this.rxTransferFG.valid && showRxTransferOut" [id]="'Print'"><span>R</span>
            Transfer-Out and Print
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'r'" (click)="RxTransferIn('print')"
            *ngIf="this.rxTransferFG.valid && !showRxTransferOut" [id]="'Print'"><span>R</span>
            Transfer-In and Print
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'o'" (click)="updateFax()"
            *ngIf="this.rxTransferFG.valid && showRxTransferOut && hasFaxNumber" [id]="'TransferOutFax'"><span>O</span>
            Transfer-Out and Fax
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'f'" (click)="RxTransferIn('TransferOut')"
            *ngIf="this.rxTransferFG.valid && showRxTransferOut" [id]="'Transfer'"><span>F</span>
            Transfer-Out
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'i'" (click)="RxTransferIn()"
            *ngIf="this.rxTransferFG.valid && showRxTransferIn" [id]="'Transfer'"><span>I</span>
            Transfer-In
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="routeBackToPrevScreen('Normal')"
            id="cancel"><span>C</span> Cancel</button>
    </div>
    <div class="col-md-7 text-right pull-right"
        *ngIf="(showRxTransferIn || showRxTransferOut) && frmPatHis">
        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferOut">
            Transfer-Out and Print
        </button>
        <button class="inactive" *ngIf="!this.rxTransferFG.valid && !showRxTransferOut">
            Transfer-In and Print
        </button>
        <button class="inactive" *ngIf="(!this.rxTransferFG.valid || !hasFaxNumber) && showRxTransferOut">
            Transfer-Out and Fax
        </button>
        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferOut">
            Transfer-Out<span *ngIf="showRxTransferIn">Transfer-In</span> </button>
        <button class="inactive" *ngIf="!this.rxTransferFG.valid && showRxTransferIn">
            Transfer-In</button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'r'" (click)="RxTransferIn('print')"
            *ngIf="this.rxTransferFG.valid && showRxTransferOut" [id]="'Print'"><span>R</span>
            Transfer-Out and Print
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'r'" (click)="RxTransferIn('print')"
            *ngIf="this.rxTransferFG.valid && !showRxTransferOut" [id]="'Print'"><span>R</span>
            Transfer-In and Print
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'o'" (click)="updateFax()"
            *ngIf="this.rxTransferFG.valid && showRxTransferOut && hasFaxNumber" [id]="'TransferOutFax'"><span>O</span>
            Transfer-Out and Fax
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'f'" (click)="RxTransferIn('TransferOut')"
            *ngIf="this.rxTransferFG.valid && showRxTransferOut" [id]="'Transfer'"><span>F</span>
            Transfer-Out
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'f'" (click)="RxTransferIn()"
            *ngIf="this.rxTransferFG.valid && showRxTransferIn" [id]="'Transfer'"><span>F</span>
            Transfer-In
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="routeBackToPrevScreen('Normal')"
            id="cancel"><span>C</span> Cancel</button>
    </div>

    </div>
</div>



<ng-template #warningInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="clearRxdata()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <span [innerHTML]="warningMessage" *ngIf="holdRx"></span>
                        <label *ngIf="!holdRx">{{warningMessage}}</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button id="myInput" type="button" autofocus class="hotkey_primary" (keydown.enter)="unHoldRx('hold')"
            (click)="unHoldRx('hold')" *ngIf="holdRx" appShortcutKey InputKey="u"> <b>U</b>UnHold Rx </button>
        <button id="myInput" type="button" autofocus class="hotkey_primary" (keydown.enter)="clearRxdata()"
            (click)="clearRxdata()" *ngIf="!holdRx" appShortcutKey InputKey="o"> <b>o</b>OK</button>
        <button id="myInput" type="button" class="hotkey_primary" (keydown.enter)="clearRxdata()"
            (click)="clearRxdata()" *ngIf="holdRx" appShortcutKey InputKey="c"> <b>C</b> Cancel</button>
    </div>
</ng-template>
<!-- <app-transfer-pharmacy [PharmacyFG]="addPharFG" (Result)="addNewPharResult($event)" *ngIf="addPopUp" (IsPopUpClosed)="closeCommonPopUp($event)"
            (EditResult)="editPharResult($event)" [IsEditMode]="isedit"></app-transfer-pharmacy> -->



<ng-template #UPDATE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Fax Confirmation </h4>
        <span body class="overflow_unset">
            <eprx-input [LabelText]="'Fax#'" [PlaceHolder]="''" [ControlName]="'fax'"
            [FormGroupName]="faxFG" [MaskPattern]="'(000)000-0000'" [DropSpclChar]="true"
            [ShowMask]="true" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}">
            </eprx-input>
        </span>
        <button footer class="hotkey_success" (click)="updateFaxToPharm()" appShortcutKey [AltKey]="'u'">
            <span>U</span>Update and Fax </button>
        <!-- <button footer *ngIf="noSelcted" class="inactive" appShortcutKey>Update and Fax </button> -->
        <button footer class="hotkey_success" (click)="openFax()" appShortcutKey [AltKey]="'f'">
            <span>F</span> Fax </button>
        <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"> <span>C</span>
            Cancel
        </button>
    </app-custom-modal>
</ng-template>

<!-- <ng-template #FAX let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalForFax()">
        <h4 header>Transfer Out Fax </h4>
        <span body style="overflow: unset;">
            <eprx-input [LabelText]="'To Fax No'" [Title]="'To Fax No'" [ControlName]="'tofax'" [FormGroupName]="faxFG"
                [PlaceHolder]="'To Fax Number'" [MaskPattern]="'(000)000-000000000'" [ShowMask]="true"
                (TriggerOnBlur)="toFaxNumber($event)" (TriggerChangeValue)="toFaxNumber($event)">
            </eprx-input>
        </span>
        <button footer *ngIf="disabled" class="inactive">Ok</button>
        <button footer *ngIf="!disabled" class="hotkey_primary" appShortcutKey [CtrlKey]="'o'" (click)="fax()">
            <span>O</span> Ok </button>
        <button footer class="hotkey_primary" (click)="closeModalForFax()" appShortcutKey [CtrlKey]="'c'">
            <span>C</span> Cancel
        </button>
    </app-custom-modal>
</ng-template> -->


<ng-template #FAXCHANGE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalForFaxChanged(false)">
        <h4 header>Fax# Changed</h4>
        <span body class="overflow_unset">
            Fax# has been changed, do you wish to update the Transfer Pharmacy Fax# and send Fax?
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="y" (click)="faxChanged()">
            <b>Y</b> YES </button>
        <button footer class="hotkey_primary" (click)="closeModalForFaxChanged(false)" appShortcutKey InputKey="n">
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #deceased let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning</h4>
        <button header type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click'); closeModalForDeceased()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">Selected Patient <b>{{warningMsgDeceased}}</b> is deceased. Rx(s) of this patient cannot be Transferred out.</div>
    <div class="modal-footer">
        <button ngbAutofocus type="button" class="hotkey_primary" (click)="d('Cross click'); closeModalForDeceased()" appShortcutKey InputKey="o">
           <b>O</b> OK
        </button>
    </div>
</ng-template>
<ng-template #DiscontinuedRx let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span >Warning!!</span>
    </div>
    <div class="modal-body">
        <p>Rx is Discontinued. If you wish to continue the Rx will no more be marked as discontinued.
            <br/> Would you like to proceed?</p>
    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
        (click)="setOrderStatus(); c('Close click')"><b>y</b>Yes</button>
        <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
        (click)="clearRxdata(); c('Close click')"><b>n</b>No</button>
    </div>
</ng-template>
