import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { WijmoAutoSaveService } from './wijmo-auto-save.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private _commonSer: CommonService, private _WijmoAutoSaveService: WijmoAutoSaveService) { }
  setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  getLocalStorage(key) {
        return localStorage.getItem(key);
  }
  removedLocalStorage(key) {
    localStorage.removeItem(key);
  }
  setSessionStorage(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  getSessionStorage(key) {
    return sessionStorage.getItem(key);
  }
  removedSessionStorage(key) {
    sessionStorage.removeItem(key);
  }
  storeWijmoGridDataInSession(flex) {
    const gridData = flex.columns.map(column => ({
      header: column.header,
      width: column.width,
      isVisible: column.isVisible
    }));
    this.setSessionStorage('flexGridData', gridData);
    this.setSessionStorage('clientWidth', flex["_e"].clientWidth);
  }
  getWijmoGridDataInSession(wjHeaders,key) {
    let wijmoSavedWidths = this.getSessionStorage("flexGridData");
    let clientWidth = this.getSessionStorage("clientWidth")
        if (wijmoSavedWidths) {
            this._WijmoAutoSaveService.storeWijimoStatus( JSON.parse(wijmoSavedWidths) ,key, wjHeaders, clientWidth);
            this.removedSessionStorage("flexGridData");
            this.removedSessionStorage("clientWidth");
        }
  }
}
