import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MsgConfig } from 'src/app/app.messages';
import { PharmacyLabels } from 'src/app/models/labels.models';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';

@Component({
  selector: 'app-pharmacy-selection',
  templateUrl: './pharmacy-selection.component.html',
  styleUrls: ['./pharmacy-selection.component.scss']
})
export class PharmacySelectionComponent implements OnInit {
@Input() isfromPatientHistory:boolean;
sourcepharmacyInfo: any;
isRequestedInfo: boolean = false;
@Input()
set RequestedInfo(pharmacy : any){
  console.log(pharmacy , " pharmacy");
  if(pharmacy?.PrescriptionData?.PrescriptionData?.PharmacyDetail?.SourcePharmacy)
   {
    this.isRequestedInfo = true;
    this.sourcepharmacyInfo = pharmacy.PrescriptionData.PrescriptionData.PharmacyDetail.SourcePharmacy;
     this.selectedpharmDetails();
   } else {
    this.isRequestedInfo = false;
   }
}
selectedpharmacyDetails: any;
IsFocusField: any;
rxTransferFG: FormGroup;
pharmacyabels = new PharmacyLabels();
public citySelected = null;
public stateSelected = null;
modalRef: any;
@Output() selectedPharm = new EventEmitter();
pharmacySearchValue: any = "";
addPharFG: FormGroup;
msgConfig = MsgConfig;
addPopUp = false;
isedit = false;
newPharId: any;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _patientUtil: PatientInfoUtil
  ) {
   }

  ngOnInit(): void {
    
  }
  selectedPharmDetails(pharmacy) {
    this.selectedpharmacyDetails = pharmacy;
      this.selectedPharm.emit(pharmacy);
  }

  openPharmacyEdit() {
      this.isedit = false;
      this._cdr.detectChanges();
      this.isedit = true;
  }
  selectedpharmDetails() {
    if(this.sourcepharmacyInfo)
    {
      let phFax: any = "", phPone: any = "";
      if(this.sourcepharmacyInfo.PhoneNumbers?.length) {
        // to find the Fax number
        phFax =  this.sourcepharmacyInfo.PhoneNumbers.find((item: any) => { return item.Type === 'Fax' });
           // to find the Phone 1 number
        phPone =  this.sourcepharmacyInfo.PhoneNumbers.find((item: any) => { return item.Type === 'Phone1' });
      }
      this.selectedpharmacyDetails = {
        PharmacyName : this.sourcepharmacyInfo.BusinessName,
        NPINum  : this.sourcepharmacyInfo.Npi, 
        Fax  : phFax ? phFax.Number : '', 
        Phone1  :  phPone ? phPone.Number : '', 
        addresses : this._patientUtil.getPatientAddress({
          address1: this.sourcepharmacyInfo?.Address?.AddressLine1,
          address2:this.sourcepharmacyInfo?.Address?.AddressLine2,
          city: this.sourcepharmacyInfo?.Address?.City,
          state: this.sourcepharmacyInfo?.Address?.State,
          zipcode: this.sourcepharmacyInfo?.Address?.ZipCode,
        }),
      }
    }
  }




}
