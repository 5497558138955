<div
    [ngClass]="[
        isInputElemHidden
            ? 'form-group custom--input custom--date custom--select__height-hide'
            : cssStyle? 'custom--date default': 'form-group custom--input custom--date default',
        errorMessage ? 'has-error' : '', (rxID === 'rxOtherAmnt' && isDisabled) ? 'input-mb-increase': ''
    ]"
>
    <label class="control-label">
        <span *ngIf="labelText || isInputElemHidden || (!isInputElemHidden && isTouched)" [ngbTooltip]="(rxID === 'rxOtherAmnt') ? msgToolTip : ''"
        tooltipClass="search-tooltip-pat oth-amt-tooltip"  triggers="mouseover:mouseleave">{{ labelText }}
            <span *ngIf="isRequired" class="help--text__danger">*</span>
            <span *ngIf="errorMessage && (errorMessage.includes('Value must be greater than zero'))"
              class="help--text__danger ml-2" title="{{errorMessage}}"><i class="anime-shake far fa-exclamation-circle font_size_1_2rem" ></i></span>
            <span *ngIf="errorMessage && (errorMessage.includes('U&C charge cannot be zero if you wish to set zero as U&C, please set the Allow U&C zero in the insurance that you are billing'))"
            class="help--text__danger ml-2" title="{{errorMessage}}"><i class="anime-shake far fa-exclamation-circle font_size_1_2rem" ></i></span>
            <span
                *ngIf="isInputElemHidden && !notDisEditIcon"
                (click)="setInputElemHidden()"
                class="help--text__icons"
            >
                <i  *ngIf="showRefresh" [title]="labelText ? 'Refresh ' + labelText : 'Refresh' " [ngClass]="!IsAwpPriceUpdate ? 'cursor_pointer_disable' : '' "  class="far fa-redo-alt custom_font_size_18 mt-1"></i>
                <i  *ngIf="!showRefresh" [title]="labelText ? 'Edit ' + labelText : 'Edit' " class="far fa-pencil-alt"></i>
            </span>
            <span
                *ngIf="!isInputElemHidden && isTouched"
                (click)="setIsTouchedFalse()"
                class="help--text__icons"
            >
                <i class="far fa-save"></i>
            </span>
        </span>
        <fieldset [id]="rxID + 'f'" [disabled]="isDisabled || isReadOnly">
            <!-- [ngStyle]="{'margin-top': (rxID === 'rxOtherAmnt') ? '2rem' : '0rem'}" -->
            <span
                *ngIf="hasControl && inputFormGroup"
                [formGroup]="inputFormGroup"
                [ngClass]="formatType === 'currency' ? 'curency-format' : ''"
            >
                <span *ngIf="!isInputElemHidden" [ngClass]="IsSpace ? 'eprx-price-info': ''">
                    <div
                        class="input-btn-group-prepend"
                        *ngIf="formatType === 'currency'"
                    >
                        <i class="far fa-dollar-sign"></i>
                    </div>
                    <span *ngIf="decimalValue === 0">
                        <input
                            autocomplete="no"
                            [id]="rxID"
                            [type]="inputType"
                            class="form-control"
                            appInputRestriction
                            [formControl]="inputFormGroup.get(custContrlName)"
                            [placeholder]="placeHolder ? placeHolder : ''"
                            [tabindex]="tabIndex"
                            appChangeDebounce
                            (debounceChange)="changeValue($event)"
                            [debounceTime]="debounceTime"
                            [appAutofocus]="autoFocus"
                            [required]="isRequired"
                            [pattern]="validPattern"
                            [minlength]="minLength"
                            [maxlength]="maxLength"
                            [dropSpecialCharacters]="dropSpclChar"
                            (blur)="triggerChange($event)"
                            (blur)="triggerOnBlur($event)"
                            [prefix]="prefix"
                            [mask]="maskPattern"
                            [readOnly]="isReadOnly"
                            (keydown)="inputValue = $event?.target?.value"
                            (change)="emitOnChange($event)"
                            [title]="titleText || ''"
                            (keydown.enter)="onEnter($event)"
                            (blur)="
                                validateAllFormFields(
                                    inputFormGroup,
                                    custContrlName,
                                    $event
                                )
                            "
                            (focus)="onFocus($event)"
                            (keydown.Tab)="onTabbingEvent($event)"
                            appNumberCurrency
                            [decimalValue]="decimalValue"
                            [beforeDecimalVal]="beforeDecValue"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            [InputType]="formatType ? formatType : inputType"
                            (blur)="setNumberFormat()"
                            [value]="
                                value
                                    ? value
                                    : this.inputFormGroup.value[
                                          this.custContrlName
                                      ] ||
                                      this.inputFormGroup.value[
                                          this.custContrlName
                                      ] === 0
                                    ? this.inputFormGroup.value[
                                          this.custContrlName
                                      ]
                                    : null
                            "
                            (input) = "inputValue = $event?.target?.value"
                            (keydown.shift.tab)="onShiftTabbingEvent($event)"
                            [showMaskTyped] = "showMask"
                            [isRxNumber] = "isRxNumber"
                            [EnteredMaxLength]="EnteredMaxLength"
                        />
                        <div class="clear--icon" (click)="clearSelection()" *ngIf="showClear">
                            <i class="fas fa-times"></i>
                        </div>
                    </span>
                    <span *ngIf="decimalValue !== 0">
                        <input
                            autocomplete="no"
                            [id]="rxID"
                            [type]="inputType"
                            class="form-control"
                            [ngClass]="inputType === 'password' ? 'text-display-notes' : ''"
                            appInputRestriction
                            [formControl]="inputFormGroup.get(custContrlName)"
                            [placeholder]="placeHolder ? placeHolder : ''"
                            [tabindex]="tabIndex"
                            appChangeDebounce
                            (change)="emitOnChange($event)"
                            (debounceChange)="changeValue($event)"
                            (input)="onInput();inputValue = $event?.target?.value"
                            [debounceTime]="debounceTime"
                            [appAutofocus]="autoFocus"
                            [required]="isRequired"
                            [pattern]="validPattern"
                            [minlength]="minLength"
                            [maxlength]="maxLength"
                            [dropSpecialCharacters]="dropSpclChar"
                            (blur)="triggerChange($event)"
                            (blur)="triggerOnBlur($event)"
                            [prefix]="prefix"
                            [mask]="maskPattern"
                            [readOnly]="isReadOnly"
                            (keydown)="inputValue = $event?.target?.value"
                            [title]="titleText || ''"
                            (keyup.enter)="onEnter($event)"
                            (blur)="validateAllFormFields(inputFormGroup, custContrlName,$event)"
                            (focus)="onFocus($event)"
                            appNumberCurrency
                            [decimalValue]="decimalValue"
                            [beforeDecimalVal]="beforeDecValue"
                            [InputType]="formatType ? formatType : inputType"
                            (blur)="setNumberFormat()"
                            (keydown.Tab)="onTabbingEvent($event)"
                            (keydown.shift.tab)="onShiftTabbingEvent($event)"
                            [showMaskTyped] = "showMask"
                            (keydown)="shortcutKeysTrigger($event)"
                            (keyup)="resetclicks($event)"
                            BarcodeDetector
                            [BarcodeDetector]="barCodeDetector"
                            [forRxSerialBarCode]="forRxSerial"
                            (EmitBarcode)="getBarcodeInfo($event)"
                            licenseFormat
                            (blur)="emitBlurEvent($event)"
                            [isRxNumber] = "isRxNumber"
                            [EnteredMaxLength]="EnteredMaxLength"
                        />
                        <div class="clear--icon" (click)="clearSelection()" *ngIf="showClear">
                            <i class="fas fa-times"></i>
                        </div>
                    </span>
                </span>

                <span *ngIf="isInputElemHidden">
                    <div class="label--data">
                        <span
                            *ngIf="
                                (inputFormGroup?.value)[custContrlName] ||
                                (inputFormGroup?.value)[custContrlName] === 0
                            "
                        >
                            <span *ngIf="formatType === 'currency'">
                                <i class="far fa-dollar-sign"></i>
                            </span>
                            &nbsp; 
                            <span *ngIf="IsFromFill">
                                {{  this._commonSer?.checkIsPartialFill(PrescReFill,Prescription, rxType) ? (inputFormGroup?.value)[custContrlName] : '-' }} </span> 
                                <span *ngIf="!IsFromFill">
                                    {{ (inputFormGroup?.value)[custContrlName] }} </span> 

                        </span>
                        <span
                            *ngIf="
                                !(inputFormGroup?.value)[custContrlName] &&
                                (inputFormGroup?.value)[custContrlName] !== 0
                            "
                        >
                            --
                        </span>
                    </div>
                </span>
            </span>
            <span
                *ngIf="!hasControl"
                [ngClass]="formatType === 'currency' ? 'curency-format' : ''"
            >
                <span *ngIf="!isInputElemHidden" [ngClass]="IsSpace ? 'eprx-price-info': ''">
                    <div
                        class="input-btn-group-prepend"
                        *ngIf="formatType === 'currency'"
                    >
                        <i class="far fa-dollar-sign" [ngClass]="cssStyle ? 'dollar-inline--align' : ''"></i>
                    </div>
                    <span *ngIf="decimalValue === 0">
                        <input
                            autocomplete="no"
                            [id]="rxID"
                            [type]="inputType"
                            appInputRestriction
                            class="form-control"
                            [placeholder]="placeHolder ? placeHolder : ''"
                            appChangeDebounce
                            [tabindex]="tabIndex"
                            (debounceChange)="changeValue($event)"
                            [debounceTime]="debounceTime"
                            [required]="isRequired"
                            (keydown.enter)="onEnter($event)"
                            [appAutofocus]="autoFocus"
                            [pattern]="validPattern"
                            (blur)="triggerChange($event)"
                            (blur)="triggerOnBlur($event)"
                            [prefix]="prefix"
                            [readOnly]="isReadOnly"
                            (keydown)="inputValue = $event?.target?.value"
                            (change)="emitOnChange($event)"
                            [dropSpecialCharacters]="dropSpclChar"
                            [mask]="maskPattern"
                            (input) = "inputValue = $event?.target?.value"
                            [title]="titleText || ''"
                            [value]="inputValue"
                            (focus)="onFocus($event)"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            (keydown.Tab)="onTabbingEvent($event)"
                            (keydown.shift.tab)="onShiftTabbingEvent($event)"
                            [showMaskTyped] = "showMask"
                            appNumberCurrency
                            (keypress)="checkMaxLength($event)"
                            [beforeDecimalVal]="beforeDecValue"
                            [InputType]="formatType ? formatType : inputType"
                            [minlength]="minLength"
                            [maxlength]="maxLength"
                            [isRxNumber] = "isRxNumber"
                            [EnteredMaxLength]="EnteredMaxLength"
                        />
                        <div class="clear--icon" (click)="clearSelection()" *ngIf="showClear">
                            <i class="fas fa-times"></i>
                        </div>
                    </span>
                    <span *ngIf="decimalValue !== 0">
                        <input
                            autocomplete="no"
                            [id]="rxID"
                            [type]="inputType"
                            appInputRestriction
                            [placeholder]="placeHolder ? placeHolder : ''"
                            appChangeDebounce
                            [tabindex]="tabIndex"
                            (debounceChange)="changeValue($event)"
                            [debounceTime]="debounceTime"
                            [required]="isRequired"
                            (keydown.enter)="onEnter($event)"
                            [appAutofocus]="autoFocus"
                            [pattern]="validPattern"
                            (blur)="triggerChange($event)"
                            (blur)="triggerOnBlur($event)"
                            [prefix]="prefix"
                            (change)="emitOnChange($event)"
                            [dropSpecialCharacters]="dropSpclChar"
                            [mask]="maskPattern"
                            [title]="titleText || ''"
                            [value]="inputValue"
                            (input) = "inputValue = $event?.target?.value"
                            (focus)="onFocus($event)"
                            [readOnly]="isReadOnly"
                            (keydown)="inputValue = $event?.target?.value"
                            (keydown.Tab)="onTabbingEvent($event)"
                            (keydown.shift.tab)="onShiftTabbingEvent($event)"
                            [showMaskTyped] = "showMask"
                            appNumberCurrency
                            [beforeDecimalVal]="beforeDecValue"
                            (keypress)="checkMaxLength($event)"
                            [InputType]="formatType ? formatType : inputType"
                            (keydown)="shortcutKeysTrigger2($event)"
                            (keyup)="resetclicks($event)"
                            [decimalValue]="decimalValue"
                            [ngClass]="cssStyle ? (formatType === 'currency' ? 'form-control height_2_2rem' : (rxID === 'LotNumDPV' ? 'form-control height_2_5rem pl-1' : 'form-control height_2_2rem pl-1')) : 'form-control'"
                            [minlength]="minLength"
                            [maxlength]="maxLength"
                            [isRxNumber] = "isRxNumber"
                            [EnteredMaxLength]="EnteredMaxLength"
                        />
                        <div class="clear--icon" title="Remove Sig" (click)="clearSelection()" *ngIf="showClear">
                            <i class="fas fa-times"></i>
                        </div>
                    </span>
                </span>
                <span *ngIf="isInputElemHidden">
                    <div class="label--data">
                        <span *ngIf="inputValue || inputValue === 0">
                            <span *ngIf="formatType === 'currency'">
                                <i class="far fa-dollar-sign"></i>
                            </span>
                            &nbsp; {{ inputValue }}
                        </span>
                        <span *ngIf="!inputValue && inputValue !== 0">
                            --
                        </span>
                    </div>
                </span>
            </span>

        </fieldset>
    </label>
    <div class="help-block" *ngIf="errorMessage && !(errorMessage.includes('Value must be greater than zero')) && !(errorMessage.includes('U&C charge cannot be zero if you wish to set zero as U&C, please set the Allow U&C zero in the insurance that you are billing'))">
        {{ errorMessage }}
    </div>
</div>

<ng-template #msgToolTip>
    <div class="tooltip--top">
        <div class="row text-left">
            <div class="col-md-12 font-header-size mt-2">
                <label class="tp-header-text">Other Amount + Other Fee + Tax</label>
            </div>
            <p class="font-innertext-size mt-3">
                Other Amount = Other Amount1 + Other Amount 2 + Other Amount 3.
            </p>
            <p class="font-innertext-size">
                Other Fee = Incentive amount + Profession Service Fee.
            </p>
            <p class="font-innertext-size">
                Tax = Flat Sales Tax amount + Percentage Sales Tax Amount.<br>
                Here - Percentage Sales Tax amount is Sales Tax percentage applied on the Usual and Customary amount.
            </p>
        </div>
    </div>
</ng-template>
