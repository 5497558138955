
export class PharmacyAddress {
    AddressLine1: string = null;
    AddressLine2: string = null;
    City: string = null;
    State: string = null;
    ZipCode: any = null;
    CountryCode: any = null;
}
export class OrganizationRequest
{
    VendorType: string = null;
    RequestingPharmacyNABP: string = null;
    OrganizationNPI: string = null;
    OrganizationName: string = null;
    OrganizationAddress: string = null;
    OrganizationCity: string = null;
    OrganizationStateProvince: string = null;
    OrganizationPrimaryPhoneNumber: string = null;
    OrganizationPostalCode: string = null;
    OrganizationToken: string = null;
}