
import { Observable, empty as observableEmpty, of } from 'rxjs';

import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/finally";
import { catchError, delay, finalize, map, mergeMap, retryWhen } from 'rxjs/operators';
import * as moment from "moment";
import { constant } from "..";
import { environment } from "../../environments/environment";
import { CommonStore } from '../store';
import { AlertService } from "./alert.service";
import { CommonService } from "./common.service";
import { LoaderService } from "./loader.service";
import { UserService } from "./user.service";
const baseUrl = environment.baseUrl;
const i18nUrl = environment.baseUrlTranslations;
const interfaceHubUrl = environment.interfaceHubUrl;
const reportWriterUrl = environment.reportWriterbaseUrl;
const formsUrl = environment.formsbaseUrl;
const documentsUrl = environment.documentBaseurl;
const primecommproxyBaseUrl = environment.primecommproxy_BaseUrl;
const primecommBaseUrl = environment.primecomm_BaseUrl;
const retryErrorStatuList:number[] = [500, 501, 502, 503, 504 ];
const priorAuthUrl = environment.priorAuthBaseUrl;
const prescriptionBaseUrl = environment.prescriptionBaseUrl
@Injectable()
export class BaseHttpInterceptor implements HttpInterceptor {
    private totalRequests = 0;
    endTime :number;
    interfaceToken: any;

    constructor(
        private _userServ: UserService,
        private _loadrServ: LoaderService,
        private _commonSer: CommonService,
        private _alertServ: AlertService,
        private _cmnStr: CommonStore
    ) {
        this._commonSer.systemSettings$.subscribe(resp => {
            if (resp) {
                this.endTime = this._commonSer.getSetttingValue(
                    "PharmacySettings",
                    "IN_ACTIVITY_TIMEOUT"
                );
                // this.endTime = 1;
            }
        });
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const stopAddingDefURL = req.headers.get("stopAddingDefURL") === "true";
        const isWebPubSubURL = req.headers.get("WebPubSubURL") === "true";
        const doNotDefBaseUrl = req.headers.get("doNotDefBaseUrl") === "true";
        const logicoy = req.headers.get("logicoyToken");
        const authorizeInterfaceToken = sessionStorage.getItem("InterfaceToken") ? JSON.parse(this._commonSer.DecryptData(sessionStorage.getItem("InterfaceToken"))) : "";
        const frRprttWriterBaseUrl = req.headers.get("frRprttWriterBaseUrl") === "true";
        // const reportWriterUrlToken = sessionStorage.getItem("CmnMicroSvcToken") ? JSON.parse(this._commonSer.DecryptData(sessionStorage.getItem("CmnMicroSvcToken"))) : "";
        // const access_token = this._userServ.getAccessToken();
        const formBaseUrl = req.headers.get("formsBaseUrl") === "true";
        const frDocumnetBaseUrl = req.headers.get("frDocumnetBaseUrl") === "true";
        const isSPCBaseUrl = req.headers.get("isSPCBaseUrl") === "true";
        const isSPCNoProxyBaseUrl = req.headers.get("isSPCNoProxyBaseUrl") === "true";
        const microSvcUrlToken = sessionStorage.getItem("CmnMicroSvcToken") ? JSON.parse(this._commonSer.DecryptData(sessionStorage.getItem("CmnMicroSvcToken"))) : "";
        const frpriorAuthBaseUrl = req.headers.get("frpriorAuthBaseUrl") === "true";
        const frprescriptionBaseUrl = req.headers.get("frprescriptionBaseUrl") === "true";
        const access_token = this.checkAndGetToken2(req);
        let showLoader = true;
        if (this.checkAndSetTimeout(req.url)) {
            showLoader = false;
            // return EMPTY;
        }
        req = req.clone({
              headers: req.headers.set(
                "Authorization",
                   logicoy
                  ? "Basic " + logicoy 
                  : ((authorizeInterfaceToken && doNotDefBaseUrl)
                  ? "Bearer " + authorizeInterfaceToken
                  : (microSvcUrlToken && (frRprttWriterBaseUrl || formBaseUrl || frDocumnetBaseUrl || isSPCBaseUrl || isSPCNoProxyBaseUrl || isWebPubSubURL || frpriorAuthBaseUrl || frprescriptionBaseUrl))
                  ? "Bearer " + microSvcUrlToken
                  : access_token
                  ? "Bearer " + access_token
                  : ""
              )).set("TimeZoneOffset", new Date().getTimezoneOffset().toString())
                // .set("Content-Security-Policy", "default-src 'self'")
                // .set("Strict-Transport-Security", "max-age=31536000; includeSubDomains")
                // .set("X-Frame-Options", "SAMEORIGIN")
                // .set("X-Content-Type-Options", "nosniff")
                // .set("Referrer-Policy", "no-referrer")
                .set("TenantNPI", this._userServ.getToken("UniquePharmacyId"))
                .set("TenantId", this._userServ.getToken("TenantId"))
                .set("PharmacistId", this._userServ.getToken("PharmacistId"))
                .set("TechnicianId", this._userServ.getToken("TechnicianId"))
                .set("UserId", this._userServ.getToken("UserId")),                
                // .set("Permissions-Policy", "accelerometer=(), camera=(), geolocation=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=()"),
                url:
                req.url.indexOf("assets/i18n") > 0
                    ? i18nUrl + req.url
                    : isSPCBaseUrl ? (primecommproxyBaseUrl + req.url)
                    : isSPCNoProxyBaseUrl ? (primecommBaseUrl + req.url)
                    : isWebPubSubURL ? (req.url)
                    : (doNotDefBaseUrl ? (interfaceHubUrl  + req.url) : frRprttWriterBaseUrl ? (reportWriterUrl + req.url) : formBaseUrl ? (formsUrl + req.url) : frDocumnetBaseUrl ? (documentsUrl + req.url) : frpriorAuthBaseUrl ? (priorAuthUrl + req.url) : frprescriptionBaseUrl ? (prescriptionBaseUrl + req.url) :
                    (!stopAddingDefURL ? (req.url.includes("chart") || req.url.includes("logicoy") ? "" : baseUrl) + req.url : req.url))
        });
        const isLoadNotReq = req.headers.get("isLoadNotReq");
        const loaderMsg = req.headers.get("loadingMesssage");
        if (isLoadNotReq !== "true" && showLoader) {
            this.totalRequests++;
            this.startLoader();
        }

        if (loaderMsg) {
            this.showLoaderMsg(loaderMsg);
        }
       const  delayMs = 500;
        const maxRetries = 2;
        return next.handle(req).pipe(
            retryWhen(errors => errors.pipe(
                mergeMap((error: any, index) => {
                  if (index < maxRetries && retryErrorStatuList?.includes(error?.status) && !error?.error?.EventKey && ( (req?.url?.includes("web-app") || req?.url?.includes("web")) ? req?.method === 'GET' : true)) {
                    return of(error).pipe(delay(delayMs));
                  }
                  throw error;
                })  
              )),          
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if(event && event.body && event.body.EventKey){
                        this._alertServ.warning(event.body.EventKey);
                    } else if (event && event.body && event.body.ErrorMessage) {
                        this._alertServ.warning(event.body.ErrorMessage);
                    } else if(event && event.body && event.body[0] && event.body[0].ErrorMessage){
                        this._alertServ.warning(event.body[0].ErrorMessage);
                    }
                    if (event.url.includes("Reports/AsyncReports")) {
                        this.hideLoader();
                    }
                    return event;
                }
            }),
            catchError((event: HttpErrorResponse) => {
                if (event instanceof HttpErrorResponse) {
                    if (event.error && event.error instanceof Blob) {
                        let reader = new FileReader();
                        reader.onload = (readResult: Event) => {
                            const errmsg = JSON.parse((<any>readResult.target).result);
                            this._alertServ.error(errmsg.Message);
                        };
                        reader.readAsText(event.error);
                    } else if (event.error && event.error.EventKey && event.error.Message) {
                        this._alertServ.error(event.error.Message);
                    } else if (event.error && event.status === 401) {
                            if(!this._userServ.getAccessToken()) {
                                this._userServ._sessionTimeout$.next("Timeout");
                            } else {
                                this._alertServ.error("Unauthorized Access.");
                            }
                    } else if (event.error && event.url.includes('logicoy')) { // Need to access the Logicoy Url even if it has errors
                            let logicoyLink = event.error['Report']?.['ReportRequestURLs']?.['ViewableReport']?.Value
                            logicoyLink ? window.open(logicoyLink, "_blank") : null;
                    }
                    // if (event?.url.includes("Account/login"))
                    //     this._loadrServ.enableDisableSignIn(false)
                }
                return observableEmpty();
            })
        ).pipe(finalize(() => {
            if (isLoadNotReq !== "true") {
                this.decreaseRequests();
            }
            if (loaderMsg) {
                this.removeLoaderMsg();
            }
        }));
    }

    private decreaseRequests() {
        this.totalRequests--;
        if (this.totalRequests === 0 || this.totalRequests < 0) {
            this.hideLoader();
        }
    }

    private startLoader(): void {
        this._loadrServ.display(true);
    }

    private hideLoader(): void {
        this._loadrServ.display(false);
    }

    private showLoaderMsg(msg): void {
        this._loadrServ.addMessage(msg);
    }

    private removeLoaderMsg(): void {
        this._loadrServ.addMessage(null);
    }

    checkAndGetToken(req: HttpRequest<any>) {
        let access_token = this._userServ.getAccessToken();
        let overideObj = this._commonSer.getOverrideObj();
        if (overideObj.overrideEndpoint && overideObj.overrideToken &&
            overideObj.overrideEndpoint.split("_")[0].toLowerCase() === req.method.toLowerCase() &&
            this.checkIfUrlMatched(constant[overideObj.overrideEndpoint], req.url)) {
            access_token = overideObj.overrideToken;
            this._commonSer.resetOverrideObj();
        }
        return access_token;
    }

    checkAndGetToken2(req: HttpRequest<any>) {
        let access_token = this._userServ.getAccessToken();
        let overideObj = this._commonSer.getOverrideObj();
        if (overideObj && overideObj.overrideEndpoint && Array.isArray(overideObj.overrideEndpoint)) {
            let endPointToRemove;
            overideObj.overrideEndpoint.forEach(singleEndpoint => {
                if (singleEndpoint && overideObj.overrideToken &&
                    singleEndpoint.split("_")[0].toLowerCase() === req.method.toLowerCase() &&
                    this.checkIfUrlMatched(constant[singleEndpoint], req.url)) {
                    access_token = overideObj.overrideToken;
                    endPointToRemove = singleEndpoint;
                }
            });
            if (endPointToRemove) {
                this._commonSer.removeEndpoint(endPointToRemove);
            }
        } else {
            if (overideObj.overrideEndpoint && overideObj.overrideToken &&
                overideObj.overrideEndpoint.split("_")[0].toLowerCase() === req.method.toLowerCase() &&
                this.checkIfUrlMatched(constant[overideObj.overrideEndpoint], req.url)) {
                access_token = overideObj.overrideToken;
                this._commonSer.resetOverrideObj();
            }
        }
        return access_token;
    }

    checkIfUrlMatched(constEndPoint, reqUrl) {
        if (reqUrl && constEndPoint && reqUrl.indexOf(constEndPoint.split("{")[0]) >= 0 ) {
            let reqUrlArr = reqUrl.split("/");
            let endPointArr = constEndPoint.split("/");
            if (reqUrlArr.length === endPointArr.length) {
                endPointArr.forEach((segment, i ) => {
                    if (!(segment[0] === "{" && segment[segment.length - 1] === "}") && segment !== reqUrlArr[i]) {
                        return false;
                    }
                });
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkAndSetTimeout(reqUrl?) {
        // if (this._commonSer.DecryptData(localStorage.getItem("ExpireTime")) &&
        if (!reqUrl || (reqUrl && reqUrl.indexOf("version.json?") === -1) ) {
            const timeOutObserv = this._cmnStr.timeOutSession$["source"]["value"];
            if (!timeOutObserv) {
                const expireTime = localStorage.getItem("ExpireTime");
                if (expireTime) {
                    const decryptedExpireTime = this._commonSer.DecryptData(expireTime);
                    if (moment().isAfter(moment(decryptedExpireTime))) {
                        // Logout logic
                        localStorage.setItem("NOEVENT", this._commonSer.encryptData("true"));
                        this._userServ.notifyIdleTimedOut("logout");
                        if (reqUrl && reqUrl.indexOf("Account/LogOut") > -1) {
                            return false;
                        } else {
                            return true;
                        }
                    } else if(localStorage.getItem("userLogged") && this.endTime) {
                        this.increaseExpireTime();
                    }
                }
            }
             else if(localStorage.getItem("userLogged") && this.endTime) {
                this.increaseExpireTime();
            }
            return false;
        }
    }

    increaseExpireTime() {
        localStorage.setItem("NOEVENT", this._commonSer.encryptData("false"));
        localStorage.setItem("ExpireTime", this._commonSer.encryptData(moment().add(this.endTime * 60, 'seconds').toISOString()));
    }
}
