import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbDropdown, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import "rxjs/add/operator/delay";
import { takeUntil } from "rxjs/operators";
import { RxModel, SendPARequestModel } from "src/app/models";
import { AlertService, CommonService, PatPreDrugModalService, RxService, TransmissionService } from "src/app/services";
import { CommonStore, RxStore } from "src/app/store";
import { NRxUtils } from "src/app/utils";
import { environment } from "src/environments/environment";
import { OverrideLogComponent } from "../../shared";
// import { EditPatientComponent } from "src/app/modules/edit-patient";

@Component({
    selector: "app-tr-rx-ppdi-route",
    templateUrl: "./tr-rx-ppdi-route.component.html"
})
export class TrRxPpdiRouteComponent implements OnInit {
    @Input() transmResp: any;
    @Input() transmissionType: any;
    @Input() rxInfo: RxModel;
    @ViewChild('startPADrop') startPADrop: NgbDropdown;
    @Output() PatPrescDrugInsModalType = new EventEmitter<any>();
    categoryId: any;
    privType: any;
    openOverride: boolean;
    overrideFor: any;
    overrideEndpoint: any;
    alreadyPatPriv: any;
    alreadyPrescPriv: any;
    alreadyDrugPriv: any;
    alreadyInsPriv: any;
    unsubscribe$ : Subject<void> = new Subject();
    isProrAuth = environment.isProrAuth;
    PAVendorsList: any;
    PAVendorId: any;
    PriorApp: any;
    paStatusId: any;
    priorAuthId: any;
    paStatusName: string;
    showPA: boolean;
    cmmResponse: any;
    validationErrors: any;
    paStatusHistory: any;
    
    @HostListener("document:keydown.alt.x", ["$event"])
    keyEventaltX(event: KeyboardEvent) {
        if (event.altKey) {
            event.preventDefault();
            this.editPatPrescDrgIns('Patient');
          } 
    }
    @HostListener("document:keydown.alt.y", ["$event"])
    keyEventaltY(event: KeyboardEvent) {
        if (event.altKey) {
            event.preventDefault();
            this.editPatPrescDrgIns('Prescriber');
          } 
    }
    @HostListener("document:keydown.alt.z", ["$event"])
    keyEventaltZ(event: KeyboardEvent) {
        if (event.altKey) {
            event.preventDefault();
            this.editPatPrescDrgIns('Drug');
          } 
    }


    constructor(
        private _nrxUtil: NRxUtils,
        private _transmsnServ: TransmissionService,
        private _ppdims: PatPreDrugModalService,
        private _rxStore: RxStore,
        private _cmnService: CommonService,
        private _alrtServ: AlertService,
        private _rxServ: RxService,
        private _commonStore: CommonStore,
        private _modalService: NgbModal
    ) {
    }

    ngOnInit() {
        if(!this.isProrAuth) {
            this._commonStore._signlrPaQueueData$.subscribe(res => {
                if (res && res?.isReloadForPA && (res?.prescrefillId === (this.rxInfo && this.rxInfo?.PrescReFill && this.rxInfo?.PrescReFill.Id))) {
                    this.paStatusId = res && res?.paStatusId ? res?.paStatusId : 0;
                    this.paStatusName = this.paStatusId === 1 ? 'Pending PA' : this.paStatusId === 2 ? 'Approved' : this.paStatusId === 3 ? 'Submitted' : this.paStatusId === 8 ? 'Denied' : this.paStatusId === 6 ? 'Cancelled' : this.paStatusId === 7 ? 'Error' : this.paStatusId === 9 ? 'Inactive' : this.paStatusId === 11 ? 'PA not Initiated' : this.paStatusId === 12 ? 'Archived' : 'Start PA';
                }
            });
        }
        
    }

    routeToPatient() {
        this.checkUpdateErxStatusandReviewTrans("R", "PATIENT");
    }

    routeToPrescriber() {
        this.checkUpdateErxStatusandReviewTrans("R", "PRESCRIBER");
    }

    routeToDrug() {
        this.checkUpdateErxStatusandReviewTrans("R", "DRUG");
    }

    routeToInsurence() {
        this.checkUpdateErxStatusandReviewTrans("R", "INSURANCE");
    }

    async checkUpdateErxStatusandReviewTrans(_trStat, Screen) {
        if (
            this.rxInfo &&
            this.rxInfo.eRxDetailsViewModel &&
            this.rxInfo.eRxDetailsViewModel.ErxId
        ) {
            await this._nrxUtil.updateErxStatus(this.rxInfo);
        }
        await this.reviewTransmissionResult();
        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this._rxStore.storeRxInfo(this.rxInfo);
        }
        this.loadComponentAsModal(Screen);
    }

    async reviewTransmissionResult() {
        return await this._transmsnServ
            .updateUserReviewStatus(
                this.transmResp.transmissionDetails.BatchId,
                "R"
            )
            .toPromise();
    }

    loadComponentAsModal(type: string) {
        this.PatPrescDrugInsModalType.emit(type);
    }
      openOveride(content, type, endpoint, id, category) {
        this.overrideFor = content;
                const modelRef = this._modalService.open(OverrideLogComponent, {
                    centered: true,
                    keyboard: false,
                    backdrop: false,
                    windowClass: 'medium-modal-50'
                });
                modelRef.componentInstance.PrivMask = type;
                modelRef.componentInstance.CatId = content;
                modelRef.componentInstance.Comp = true;
                modelRef.componentInstance.Consolidated = true;
                modelRef.componentInstance.IsFrmInfo = true;
                modelRef.componentInstance.IsNotesDisplay = true;
                modelRef.componentInstance.category = category;
                modelRef.componentInstance.frmPriv = true;
                modelRef.componentInstance.OverrideEndpoint = endpoint
                modelRef.componentInstance.Id = id
                modelRef.result.then(res => {
                    modelRef.close();
                    if (res) {
                        if (this.overrideFor === "PatientFile") {
                            this.editPatPrescDrgIns("Patient");
                        } else if (this.overrideFor === "PrescriberFile") {
                            this.editPatPrescDrgIns("Prescriber");
                        } else if (this.overrideFor === "DrugFile") {
                            this.editPatPrescDrgIns("Drug");
                        } else if (this.overrideFor === "InsuranceFile") {
                            this.editPatPrescDrgIns("Insurance");
                        }
                    }
                });
        
            }
    async editPatPrescDrgIns(type?: string) {
        if (type === "Patient") {
            if (!this._nrxUtil.getPatientOverRidePriv() && !this.alreadyPatPriv) {
                this.alreadyPatPriv = true;
                this.openOveride('PatientFile', 'Edit', 'PUT_Patient',+this.rxInfo.Patient.patientid,'Patient');
            } else {
                this._ppdims._patPreDrugModal$.next("patient");
            const modalRef = this._ppdims.modalInstanceRef;
            const patId = this.transmResp && this.transmResp.transmissionDetails ?
            +this.transmResp.transmissionDetails.PatId : +this.rxInfo.Patient.patientid;
            modalRef.componentInstance.patientID = patId;
            modalRef.componentInstance.ClosePatientModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
                modalRef.close();
                if (resp["PatientId"]) {
                    this.rxInfo = await this._nrxUtil.getandStoreRxInfo(
                        +this.transmResp.transmissionDetails.RXNo, this.transmResp.transmissionDetails.PrescRefillId);
                }
                this._ppdims._patPreDrugModal$.next(null);
            });
            }
        } else if (type === "Prescriber") {
            if (!this._nrxUtil.getPrscrbrOverRidePriv() && !this.alreadyPrescPriv) {
                this.alreadyPrescPriv = true;
                this.openOveride('PrescriberFile', 'Edit', 'PUT_Prescriber',+this.rxInfo.Prescriber.prescriberid,'Prescriber');
            } else {
                this._ppdims._patPreDrugModal$.next("prescriber");
                const modalRef = this._ppdims.modalInstanceRef;
                const prescId = this.transmResp && this.transmResp.transmissionDetails ?
                +this.transmResp.transmissionDetails.PrescriberId : +this.rxInfo.Prescriber.prescriberid;
                modalRef.componentInstance.prescriberID = prescId;
                modalRef.componentInstance.ClosePrescriberModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
                    modalRef.close();
                    if (resp["prescriberId"]) {
                        // const prescData = await this._commonServ.getRxPrescriberDetailsById(+this.rxInfo.Prescriber.prescriberid).toPromise();
                        // this.rxInfo.Prescriber = prescData;
                    }
                    this._ppdims._patPreDrugModal$.next(null);
                });
            }
        } else if (type === "Drug") {
            if (!this._nrxUtil.hasDrugChangePrv() && !this.alreadyDrugPriv) {
                this.alreadyDrugPriv = true;
                this.openOveride('DrugFile', 'Edit', 'PUT_Drug',+this.rxInfo.Drug.id,'Drug');
            } else {
                this._ppdims._patPreDrugModal$.next("drug");
                const modalRef = this._ppdims.modalInstanceRef;
                const drugId = this.transmResp && this.transmResp.transmissionDetails ?
                +this.transmResp.transmissionDetails.DrugId : +this.rxInfo.Drug.id;
                modalRef.componentInstance.drugId = drugId;
                modalRef.componentInstance.frmAction = true;
                modalRef.componentInstance.CloseDrugModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
                    modalRef.close();
                    if (resp["drugId"]) {
                        // const drugData = await this._commonServ.getRxDrugDetailsById(+this.rxInfo.Drug.id).toPromise();
                        // this.rxInfo.Drug = drugData;
                    }
                    this._ppdims._patPreDrugModal$.next(null);
                });
            }
        } else if (type === "Insurance") {
            if (!this._nrxUtil.getInsOverRidePriv() && !this.alreadyInsPriv) {
                this.alreadyInsPriv = true;
                this.openOveride('InsuranceFile', 'Edit', 'PUT_Insurnace',this.transmResp.Insuplanid,'Insurance');
            } else {
                this._ppdims._patPreDrugModal$.next("insurance");
                const modalRef = this._ppdims.modalInstanceRef;
                const insuId = this.transmResp && this.transmResp.transmissionDetails ?
                +this.transmResp.transmissionDetails.InsuId : +this.rxInfo.RefillInsu.InsuId;
                const insurance: any = this.rxInfo.Patient.insurance.filter(
                    a => +a.insuranceid === insuId
                )[0];
                modalRef.componentInstance.insuId = insurance ? insurance.insuplanId : this.transmResp.Insuplanid;
                modalRef.componentInstance.CloseInsuModal.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                    modalRef.close();
                    this._ppdims._patPreDrugModal$.next(null);
                });
            }
        }
    }

    sendRequestForPAnotInitate() {
        var PaRejectCode = this._cmnService.getSetttingValue("WorkFlowSettings", "PA_Reject_Code");            
        var RejCodes = this.transmResp.nCPDPRecv?.RejCodes;
        if (PaRejectCode && RejCodes && this.transmResp?.transmissionDetails?.ResponseStatus === 'R') {
            PaRejectCode = PaRejectCode.split(",");
            RejCodes = RejCodes.split(",");
            if (PaRejectCode.some(item => RejCodes.includes(item))) {
                this.showPA = true;
                this.sendPARequest(0, true)
            }
        } else {
            this.showPA = false;
        }
    }

    sendPARequest(vendorId,IsPaNotIniated) {
                const rxInfo = this.rxInfo;
                let sendPARequestModel = new SendPARequestModel();
                sendPARequestModel.VendorId = +vendorId; 
                sendPARequestModel.PrescrefillId = rxInfo.PrescReFill.Id; 
                sendPARequestModel.PrescId = rxInfo.Prescriber.prescriberid; 
                sendPARequestModel.ins_code = (rxInfo.insuranceInfo && rxInfo.insuranceInfo.insuCarrier && rxInfo.insuranceInfo.insuCarrier.InsurerCode) ?
                rxInfo.insuranceInfo.insuCarrier.InsurerCode : null;
                sendPARequestModel.PrescNum = rxInfo.Prescription.PrescNum; 
                sendPARequestModel.RefillNum = rxInfo.PrescReFill.ReFillNum; 
                sendPARequestModel.FillNo = rxInfo.PrescReFill.PartialFillNo;
                sendPARequestModel.IsPaNotIniated = IsPaNotIniated;
                sendPARequestModel.PriorAuthId = this.priorAuthId;
                this._rxServ.sendOrRetryPARequest(sendPARequestModel).pipe(takeUntil(this.unsubscribe$)).subscribe(res=>{
                    if(res && res.response === true) {
                        if(res.paStatus != 7) this._alrtServ.success("PA Request Send Successfully");
                        this.paStatusId = res.paStatus;
                        this.priorAuthId = res.PriorAuthId;
                        this.PAVendorId = res.VendorId;
                        this.paStatusName = this.paStatusId === 1 ? 'Pending PA' : this.paStatusId === 2 ? 'Approved' : this.paStatusId === 3 ? 'Submitted' : this.paStatusId === 8 ? 'Denied' : this.paStatusId === 6 ? 'Cancelled' : this.paStatusId === 7 ? 'Error' : this.paStatusId === 9 ? 'Inactive' : this.paStatusId === 11 ? 'PA not Initiated' : this.paStatusId === 12 ? 'Archived' : 'Start PA';                
                    } else {
                        this._alrtServ.unsuccess("Error Occured while sending PA Request ");
                    }
                    if(+vendorId === 1) {
                        this.cmmResponse = res?.cmmResponse;
                        if(res && res?.cmmResponse && res?.cmmResponse?.actions &&  res?.cmmResponse?.actions?.start && res.cmmResponse?.actions?.start?.url) {
                            window.open(res?.cmmResponse?.actions?.start?.url,"_blank");
                        } else if(res && res?.cmmResponse && res?.cmmResponse?.actions &&  res?.cmmResponse?.actions?.info && res.cmmResponse?.actions?.info?.url) {
                            window.open(res?.cmmResponse?.actions?.info?.url,"_blank");
                        } else if(res && res?.cmmResponse && res?.cmmResponse?.error) {
                            this._alrtServ.error(res?.cmmResponse?.error);
                        }
                    }
                    if(res && res.validationErrors) {
                        this.validationErrors = res.validationErrors;
                        this._alrtServ.error("occured while Prior Approval request, Because following fields are missed: " + res.validationErrors);
                    }
                });
            }

    naviagteToPaInfo() {
        if(this.cmmResponse) {
            if(this?.cmmResponse?.actions && this?.cmmResponse?.actions?.start && this?.cmmResponse?.actions?.start?.url) {
                window.open(this?.cmmResponse?.actions?.start?.url,"_blank");
            } else if(this?.cmmResponse && this?.cmmResponse?.actions && this?.cmmResponse?.actions?.info && this?.cmmResponse?.actions?.info?.url) {
                window.open(this?.cmmResponse?.actions?.info?.url,"_blank");
            } else if(this?.cmmResponse && this?.cmmResponse?.error) {
                this._alrtServ.error(this?.cmmResponse?.error);
            }
        }
        else if(this.PriorApp) {
            if(this.PriorApp && this.PriorApp?.CmmInfoUrl && this.PriorApp.VendorId === 1) {
                window.open(this.PriorApp?.CmmInfoUrl,"_blank");
            } else if (this.PriorApp && this.PriorApp?.CmmError) {
                this._alrtServ.error(this.PriorApp?.CmmError);
            } else if(this.PriorApp && this.PriorApp?.ValidationErrors) {
                this._alrtServ.error("occured while Prior Approval request, Because following fields are missed: " + this.PriorApp?.ValidationErrors);
            } else if(this.PriorApp && this.paStatusId === 11) {
                this.startPADrop.toggle();
            }
        } else if(this.validationErrors) {
            this._alrtServ.error("occured while Prior Approval request, Because following fields are missed: " + this.validationErrors);
        }
    }
    ngAfterViewInit() {
        if(!this.isProrAuth) {
            this.PAVendorsList = JSON.parse(this._cmnService.DecryptData(sessionStorage.getItem("PAVendors")));
            this.PAVendorsList.forEach((val) => {
                if(val.isDefault === true) {
                    this.PAVendorId = val.vendorId;
                }
            });
            this.PriorApp = this.rxInfo && this.rxInfo.PriorApp ? this.rxInfo.PriorApp : null;
            this.paStatusId = this.PriorApp ? this.PriorApp?.PaStatus : 0;
            this.priorAuthId = this.PriorApp ? this.PriorApp?.Id : null; 
            this.PAVendorId = this.PriorApp ? this.PriorApp?.VendorId : null;
            this.paStatusName = this.paStatusId === 1 ? 'Pending PA' : this.paStatusId === 2 ? 'Approved' : this.paStatusId === 3 ? 'Submitted' : this.paStatusId === 8 ? 'Denied' : this.paStatusId === 6 ? 'Cancelled' : this.paStatusId === 7 ? 'Error' : this.paStatusId === 9 ? 'Inactive' : this.paStatusId === 11 ? 'PA not Initiated' : this.paStatusId === 12 ? 'Archived' : 'Start PA';
            if(this.transmResp) this.sendRequestForPAnotInitate();
            this.getPAStatusHistory();
        }
    }

    getPAStatusHistory() {
        this._rxServ.getPAStatusHistory({ rxRefillId : [(this.rxInfo?.PrescReFill.Id).toString()] }).pipe(takeUntil(this.unsubscribe$)).subscribe(res=>{
            if(res && res?.paStatusHistory?.length && res.statusCode === 0) {
                this.paStatusHistory = res?.paStatusHistory;
            } else {
                this.paStatusHistory = []
            }
        });
    }

    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}


