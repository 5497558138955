<div
    [ngClass]="[
        isInputElemHidden
            ? 'form-group custom--input custom--select custom--select__height-hide'
            : 'form-group custom--input custom--select default',
        errorMessage ? 'has-error' : '',
        isDisabled ? 'custom--disable--select' : '',
        updateDropDownPos? 'update_drop-down_position' : ''
    ]"
    [formGroup]="inputFormGroup"
    *ngIf="hasControl && inputFormGroup"
>
    <label class="control-label" *ngIf="labelText">
        <span
            >{{ labelText }}
            <span *ngIf="isRequired" class="help--text__danger">*</span>
            <i class="far fa-sync-alt color_cyan pl-2" *ngIf="frmVaccnFrm" [ngbTooltip]="'Refresh Data from MMS'" (click)="emitClickMMS()"></i>
            <span
                *ngIf="isInputElemHidden"
                (click)="setInputElemHidden()"
                class="help--text__icons"
            >
                <i [title]="labelText ? 'Edit ' + labelText : 'Edit' " class="far fa-pencil-alt"></i>
            </span>
            <span
                *ngIf="!isInputElemHidden && isTouched"
                (click)="setIsTouchedFalse()"
                class="help--text__icons"
            >
                <i class="far fa-save"></i>
            </span>
            <i class="fas fa-info-circle"  *ngIf="InformationTool" [ngbTooltip]="InformationTool"></i>
        </span>
    </label>
    <span *ngIf="!isInputElemHidden" [ngClass]="rxID === 'intakeQTypeId' ? (isDisabled ? 'eRxFormatText light_gray_for_dropdown':'eRxFormatText'): (isDisabled? 'light_gray_for_dropdown':'')">
        <!-- use  to disble select dropdown -->
        <span *ngIf="bindLabel" class="custom--select__span" [ngClass]="rxID === 'popupscreen' ? 'dropdown-scroll' :''">
            <fieldset [id]="rxID + 'f'" [disabled]="isDisabled">
            <ng-select
                #ngSelect
                [items]="filterdList"
                [appendTo]="IsAppendTo ? 'body' : '' "
                [addTag]="addItem"
                [selectOnTab]="addItem"
                [bindLabel]="bindLabel"
                [dropdownPosition]="dropdownPosition ? dropdownPosition : 'bottom'"
                [bindValue]="bindValue"
                [labelForId]="labelForId"
                [multiple]="hasMultiple"
                [attr.id]="rxID"
                [appAutofocus]="autoFocus || focusOnSelect"
                [placeholder]="placeHolder"
                clearAllText="Clear"
                [formControlName]="custContrlName"
                (change)="triggerChange($event)"
                [isOpen]="isOpen"
                (blur)="triggerOnBlur($event, ngSelect); closeDropDown()"
                [attr.tabindex]="tabIndex"
                (add)="addSelectedValue($event)"
                [required]="isRequired"
                [title]="(show2ValInInputhidden || showBindVal) ? hoverSecondValue() : (titleText || '')"
                (focus)="openDropDown(ngSelect)"
                (click)="taggleDropDown(ngSelect, $event)"
                (blur)="
                    validateAllFormFields(inputFormGroup, custContrlName);
                    closeDropDown()
                "
                (keydown)="onEnter($event, ngSelect)"
                (keyup)="filterDrpList($event, ngSelect)"
                (keyup)="onKeydownValue($event, ngSelect)"
                (keydown.Tab)="onKeyTabbing($event, ngSelect); focusOutOnTab(ngSelect)"
                [searchable]="isSearchable"
                [searchFn]="customFilterDrop"
                virtualScroll="true"
                (keydown.shift.tab)="onShiftTabbingEvent($event)"
                onReturn
                (scroll)="getWidthOnScroll()"
                (open)="getWidth()"
                [readonly]="isDisabled"
            >
            <ng-template
                    ng-option-tmp
                    *ngIf="hoverOver && bindLabel && !(hasbindlabel2 || hasbindlabel3 || hasbindlabel4 || hasbindlabel5) && !IsCardView"
                    let-item="item"
                >
                    <div class="row">
                        <div [attr.title]="!item['IsExist'] ? 'This printer is not linked to your current system or the list of available printers.' : item[bindLabel]" class="col textFont custom--select__dataflow">
                            {{item[bindLabel]}}
                        </div>
                    </div>
                </ng-template>
                <ng-template
                    ng-option-tmp
                    *ngIf="(rxID === 'intakeQTypeId') && bindLabel && !(hasbindlabel2 || hasbindlabel3 || hasbindlabel4)"
                    let-item="item"
                >
                    <div class="row">
                        <div class="col textFont ellpise notextTransform " [style.width]="bindLabelWidth+'rem'">
                            {{item[bindLabel]}}
                        </div>
                    </div>
                </ng-template>
                <ng-template *ngIf="bindLabel2 && !(hasbindlabel3 || hasbindlabel4 || hasbindlabel5) && !IsCardView" ng-header-tmp>
                    <div class="row">
                        <div class="col-md-auto custom--select__dataflow" [style.width]="bindLabelWidth+'rem'">
                            <h6>{{labelTitle1 ? labelTitle1 : "CODE"}}</h6>
                        </div>
                        <div class="col custom--select__dataflow">
                            <h6>{{labelTitle2 ? labelTitle2 : "DESCRIPTION"}}</h6>
                        </div>
                    </div>
                </ng-template>

                <ng-template
                    ng-option-tmp
                    *ngIf="bindLabel2 && !(hasbindlabel3 || hasbindlabel4 || hasbindlabel5) && !IsCardView"
                    let-item="item"
                >
                    <div class="row">
                        <div [attr.title]="''" class="col-md-auto custom--select__dataflow" [style.width]="bindLabelWidth+'rem'">
                            {{item[bindLabel]}}
                        </div>
                        <div triggers="click" [attr.title]="(isTitle && !IsNgbTooltip) ? item[bindLabel2] : ((item[bindLabel2] ==='Electronic' && item.disabled) ? 'As it is Manual Rx ELECTRONIC option is disabled' : '')"
                        class="col custom--select__dataflow"
                        [ngbTooltip]="IsNgbTooltip? item[bindLabel2] : ''"  container="body">
                            {{ item[bindLabel2]}} 
                        </div>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp *ngIf="smsTooltipForLongTitles" let-item="item">
                        <div 
                            class="text--ellipsis width_14rem"
                            tooltipClass="tooltip-auto"
                            placement="right"
                            [ngbTooltip]="item?.MessageTitle?.length > 24 ? item?.MessageTitle : ''" container="body">
                            {{ item.MessageTitle }}
                        </div>
                </ng-template>
                <ng-template *ngIf="hasbindlabel3" ng-header-tmp>
                    <div class="row">
                        <div class="col-md-auto custom--select__dataflow" [style.width]="bindLabelWidth+'rem'">
                            <h6>{{ labelTitle1 }}</h6>
                        </div>
                        <div class="col-md-auto custom--select__dataflow" [style.width]="bindLabelWidth2+'rem'">
                            <h6>{{ labelTitle2 }}</h6>
                        </div>
                        <div class="col custom--select__dataflow">
                            <h6>{{ labelTitle3 }}</h6>
                        </div>
                    </div>
                </ng-template>

                <ng-template
                    ng-option-tmp
                    *ngIf="hasbindlabel3"
                    let-item="item"
                >
                    <div class="row ">
                        <div class="col-md-auto  custom--select__dataflow" [style.width]="bindLabelWidth+'rem'">
                            {{ item[bindLabel] }}
                        </div>
                        <div class="col-md-auto custom--select__dataflow" [style.width]="bindLabelWidth2+'rem'">
                            {{ item[bindLabel2] }}
                        </div>
                        <div class="col custom--select__dataflow">
                            {{ item[bindlabel3] }}
                        </div>
                    </div>
                </ng-template>

                <ng-template *ngIf="hasbindlabel4 && !IsCardView" ng-header-tmp>
                    <div class="row">
                        <div class="col custom--select__dataflow width_2rem" [style.width]="bindLabelWidth+'rem'">
                            <h6>{{ labelTitle1 }}</h6>
                        </div>
                        <div class="custom--select__dataflow width_9rem">
                            <h6>{{ labelTitle2 }}</h6>
                        </div>
                        <div class="custom--select__dataflow width_12rem">
                            <h6>{{ labelTitle3 }}</h6>
                        </div>
                        <div class="custom--select__dataflow width_12rem">
                            <h6>{{ labelTitle4 }}</h6>
                        </div>
                        <div class="custom--select__dataflow width_24rem">
                            <h6>{{ labelTitle5 }}</h6> 
                        </div>
                    </div>
                </ng-template>
                
                <ng-template *ngIf="hasbindlabel5 && !IsCardView" ng-header-tmp>
                    <div class="row">
                        <div class="pl-3 width_12rem custom--select__dataflow">
                            <h6>{{ labelTitle1 }}</h6>
                        </div>
                        <div class="width_10rem custom--select__dataflow">
                            <h6>{{ labelTitle2 }}</h6>
                        </div>
                        <div class="width_10rem custom--select__dataflow">
                            <h6>{{ labelTitle3 }}</h6>
                        </div>
                        <div class="width_10rem custom--select__dataflow">
                            <h6>{{ labelTitle4 }}</h6>
                        </div>
                        <div class="width_20rem custom--select__dataflow">
                            <h6>{{ labelTitle5 }}</h6>
                        </div>
                        <div class="width_10rem custom--select__dataflow pl-4">
                            <h6>{{ labelTitle6 }}</h6>
                        </div>
                    </div>
                </ng-template>

                <ng-template
                    ng-option-tmp
                    *ngIf="hasbindlabel4 && !IsCardView"
                    let-item="item"

                >
                    <div class="row ">
                        <div [attr.title]="isTitle?item[bindLabel]:''" [style.width]="bindLabelWidth+'rem'" class="col custom--select__dataflow width_2rem">
                            {{ item[bindLabel] }}
                        </div>
                        <div class="custom--select__dataflow width_9rem">
                            {{ phoneLabel ? ((item[bindLabel2] | trimStrn) | mask:"(000)-000-0000000") : item[bindLabel2] }}
                        </div>
                        <div class="custom--select__dataflow width_12rem">
                            {{ phoneLabel ? ((item[bindlabel3] | trimStrn) | mask:"(000)-000-0000000") : item[bindlabel3] }}
                        </div>
                        <div class="custom--select__dataflow width_12rem">
                            {{ faxLabel ? ((item[bindlabel4] | trimStrn) | mask:"(000)-000-000000000") : item[bindlabel4] }}
                        </div>
                        <div [attr.title]="isTitle?item[bindlabel5]:''" class="custom--select__dataflow width_24rem">
                            {{ item[bindlabel5] ? item[bindlabel5] : ''}}
                        </div>
                    </div>
                </ng-template>
                <ng-template
                ng-option-tmp
                *ngIf="hasbindlabel5 && IsCardView"
                let-item="item"
            >
                <div class="row pharmacy_suggest">
                    <div class="col-8" [attr.title]="isTitle?item[bindLabel]:''"  >
                        <span class="pharmacy_label">{{ labelTitle1 }}</span> &nbsp; {{ item[bindLabel] }}
                    </div>
                    <div class="col-4" [attr.title]="isTitle?item[bindlabel6]:''"  >
                        <span class="pharmacy_label"> {{ labelTitle6 }}</span> &nbsp;    {{ item[bindlabel6] ? item[bindlabel6] : '--'  }}
                    </div>
                    <div class="col-4 mt-2">
                        <span class="pharmacy_label">{{ labelTitle2 }}</span> &nbsp; {{ (phoneLabel && item[bindLabel2]) ? ((item[bindLabel2] | trimStrn) | mask:"(000)-000-0000000") : "--" }}
                    </div>
                    <div class="col-4 mt-2">
                       <span class="pharmacy_label"> {{ labelTitle3 }}</span> &nbsp;  {{ (phoneLabel && item[bindlabel3]) ? ((item[bindlabel3] | trimStrn) | mask:"(000)-000-0000000") : "--" }}
                    </div>
                    <div class="col-4 mt-2">
                        <span class="pharmacy_label">{{ labelTitle4 }}</span> &nbsp;  {{ (faxLabel && item[bindlabel4]) ? ((item[bindlabel4] | trimStrn) | mask:"(000)-000-000000000") :  "--" }}
                    </div>
                    <div class="col-12 mt-2" [attr.title]="isTitle?item[bindlabel5]:''" >
                        <span class="pharmacy_label">{{ labelTitle5 }}</span> &nbsp;  {{ item[bindlabel5] ? item[bindlabel5] : ''}} 
                    </div>
                </div>
            </ng-template>
                <ng-template
                ng-option-tmp
                *ngIf="hasbindlabel5 && !IsCardView"
                let-item="item"
            >
                <div class="row">
                    <div [attr.title]="(isTitle)?(item[bindLabel] | uppercase):''" class="pl-3 width_12rem custom--select__dataflow">
                        {{ item[bindLabel] }}
                    </div>
                    <div class="width_10rem custom--select__dataflow ">
                        {{ phoneLabel ? ((item[bindLabel2] | trimStrn) | mask:"(000)-000-0000000") : item[bindLabel2] }}
                    </div>
                    <div class="width_10rem custom--select__dataflow ">
                        {{ phoneLabel ? ((item[bindlabel3] | trimStrn) | mask:"(000)-000-0000000") : item[bindlabel3] }}
                    </div>
                    <div class="width_10rem custom--select__dataflow ">
                        {{ faxLabel ? ((item[bindlabel4] | trimStrn) | mask:"(000)-000-000000000") : item[bindlabel4] }}
                    </div>
                    <div [attr.title]="isTitle?(item[bindlabel5] | uppercase):''" class="width_20rem custom--select__dataflow ">
                        {{ addressLabel ? item[bindlabel5] : ''}} 
                    </div>
                    <div  class="width_10rem col custom--select__dataflow ">
                        {{ item[bindlabel6] }}
                    </div>
                </div>
            </ng-template>
                <ng-template
                    ng-option-tmp
                    *ngIf="hasbindlabelMr"
                    let-item="item"
                >
                    <table
                        class="table table-striped table-bordered table-sm w-100"
                    >
                        <thead>
                            <tr class="background_primary">
                                <th scope="col">
                                    Service Level
                                </th>
                                <th scope="col">
                                    Street1
                                </th>
                                <th scope="col">
                                    city
                                </th>
                                <th scope="col">
                                    state
                                </th>
                                <th scope="col">
                                    zip
                                </th>
                                <th scope="col">
                                    phone
                                </th>
                                <th scope="col">
                                    spino
                                </th>
                                <th scope="col">
                                    isPrimary
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let val of BindValueList; let k = index"
                            >
                                <td>{{ BindValueList.ServiceLevelName }}</td>
                                <td>{{ BindValueList.AddressLine1 }}</td>
                                <td>{{ BindValueList.CityName }}</td>
                                <td>{{ BindValueList.StateName }}</td>
                                <td>
                                    {{ BindValueList.ZipCode }}
                                </td>
                                <td>{{ BindValueList.Phone }}</td>
                                <td>{{ BindValueList.SPINo }}</td>
                                <td class="text-center">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        [id]="'Ingred' + k"
                                        [(ngModel)]="BindValueList.IsPrimary"
                                    />
                                    <label
                                        class="form-check-label"
                                        [for]="'Ingred' + k"
                                    >
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-template>

                <ng-template ng-label-tmp *ngIf="show2values" let-item="item">
                    {{ item[bindLabel] }} &nbsp;&nbsp; {{ item[bindLabel2] }}
                </ng-template>
                <ng-template ng-label-tmp *ngIf="show3values" let-item="item">
                    {{ item[bindLabel] }} &nbsp;&nbsp; {{ item[bindLabel2] }} &nbsp;&nbsp; {{ item[bindlabel3] }}
                </ng-template>
                <ng-template ng-label-tmp *ngIf="showlabel2" let-item="item">
                    {{ item[bindLabel2] }}
                </ng-template>
                <ng-template ng-label-tmp *ngIf="showlabel3" let-item="item">
                    {{ item[bindlabel3] }}
                </ng-template>
                <ng-template ng-label-tmp *ngIf="showDefaultValue" let-item="item">
                        {{ inputValue }}
                </ng-template>
            </ng-select>
            </fieldset>
        </span>
        <span *ngIf="!bindLabel" class="custom--select__span" [ngClass]="isDisabled? 'light_gray_for_dropdown':''">
            <fieldset [id]="rxID + 'f'" [disabled]="isDisabled">
            <ng-select
              #ngSelect
                [attr.id]="rxID"
                [appendTo]="IsAppendTo ? 'body' : '' "
                [items]="filterdList"
                [multiple]="hasMultiple"
                [placeholder]="placeHolder"
                clearAllText="Clear"
                [addTag]="addItem"
                [selectOnTab]="addItem"
                [formControlName]="custContrlName"
                (change)="validateAllFormFields(inputFormGroup, custContrlName)"
                (change)="triggerChange($event);"
                (add)="addSelectedValue($event)"
                [isOpen]="isOpen"
                [required]="isRequired"
                [dropdownPosition]="dropdownPosition ? dropdownPosition : 'bottom'"
                [attr.tabindex]="tabIndex"
                (click)="taggleDropDown(ngSelect, $event);"
                (focus)="openDropDown(ngSelect)"
                (keydown)="onEnter($event, ngSelect)"
                (keyup)="filterDrpList($event, ngSelect)"
                (keydown.Tab)="onKeyTabbing($event, ngSelect)"
                virtualScroll="true"
                (keydown.shift.tab)="onShiftTabbingEvent($event)"
                onReturn
                (keyup)="onKeydownValue($event, ngSelect)"
                (scroll)="getWidthOnScroll()"
                (open)="getWidth()"
                [readonly]="isDisabled"
            >
            </ng-select>
            </fieldset>
        </span>
    </span>
    <span *ngIf="isInputElemHidden">
        <div class="label--data">
            <span *ngIf="(inputFormGroup?.controls)[custContrlName]?.value">
                <span *ngFor="let list of filterdList">
                    <span *ngIf="list[bindValue] == (inputFormGroup?.controls)[custContrlName]?.value
                                            ">
                        {{ list[bindLabel] }}</span>
                        <span *ngIf="list[bindValue] == (inputFormGroup?.controls)[custContrlName]?.value && bindLabel2 && show2ValInInputhidden">
                            &nbsp; - &nbsp; {{ list[bindLabel2] }}</span>
                </span>
            </span>
            <span *ngIf="!(inputFormGroup?.controls)[custContrlName]?.value">
                --
            </span>
        </div>
    </span>

    <span class="help-block" *ngIf="errorMessage">
        {{ errorMessage }}
    </span>
</div>

<div
    [ngClass]="[
    cssStyle ? 'form-group custom--select default' : isInputElemHidden
            ? 'form-group custom--input custom--select custom--select__height-hide'
            : 'form-group custom--input custom--select default',
        errorMessage ? 'has-error' : '',
        isDisabled ? 'custom--disable--select' : '',
    ]"
    *ngIf="!hasControl"
>
    <label class="control-label" *ngIf="labelText">
        <span
            >{{ labelText }}
            <span *ngIf="isRequired" class="help--text__danger">*</span>
            <span
                *ngIf="isInputElemHidden"
                (click)="setInputElemHidden()"
                class="help--text__icons"
            >
                <i class="far fa-pencil-alt"></i>
            </span>
            <span
                *ngIf="!isInputElemHidden && isTouched"
                (click)="setIsTouchedFalse()"
                class="help--text__icons"
            >
                <i class="far fa-save"></i>
            </span>
        </span>
    </label>
    <!-- use  to disble select dropdown -->
    <span class="custom--select__span"  [ngClass]="isDisabled? 'light_gray_for_dropdown':''">
        <span *ngIf="!isInputElemHidden">
            <fieldset [id]="rxID + 'f'" [disabled]="isDisabled">
            <ng-select
            #ngSelect
                [items]="filterdList"
                [attr.id]="rxID"
                [appendTo]="IsAppendTo ? 'body' : '' "
                [addTag]="addItem"
                [selectOnTab]="addItem"
                [bindLabel]="bindLabel"
                [(ngModel)]="inputValue"
                [bindValue]="bindValue"
                [dropdownPosition]="dropdownPosition ? dropdownPosition : 'bottom'"
                [labelForId]="labelForId"
                [multiple]="hasMultiple"
                [appAutofocus]="autoFocus || focusOnSelect"
                [placeholder]="placeHolder"
                clearAllText="Clear"
                (change)="triggerChange($event)"
                [title]="(show2ValInInputhidden || showBindVal) ? (hoverSecondValue() | titlecase): (titleText || '')"
                [attr.tabindex]="tabIndex"
                (focus)="openDropDown(ngSelect)"
                (keydown)="onEnter($event, ngSelect)"
                (keyup)="filterDrpList($event, ngSelect)"
                (keydown.Tab)="onKeyTabbing($event, ngSelect)"
                virtualScroll="true"
                (keydown.shift.tab)="onShiftTabbingEvent($event)"
                onReturn
                (keyup)="onKeydownValue($event, ngSelect)"
                (scroll)="getWidthOnScroll()"
                (open)="getWidth()"
                [readonly]="isDisabled"
                [ngClass]="CheckSpiServiceLevel===true ? 'service-levlcheck' : CheckSpiServiceLevel===false ? 'service-levluncheck' : cssStyle ? 'inline-height' : ''"
            >
                <ng-template
                    ng-option-tmp
                    *ngIf="forFileGenerate && bindLabel && !(hasbindlabel2 || hasbindlabel3 || hasbindlabel4)"
                    let-item="item"
                >
                    <div class="row">
                        <div [attr.title]="isTitle?(item[bindLabel] | titlecase):''" class="col textFont ellpise" [style.width]="bindLabelWidth+'rem'">
                            {{item[bindLabel] | uppercase}}
                        </div>
                    </div>
                </ng-template>
                <ng-template *ngIf="bindLabel2" ng-header-tmp>
                    <div class="row">
                        <div class="col-md-auto custom--select__dataflow">
                            <h6>CODE</h6>
                        </div>
                        <div class="col custom--select__dataflow">
                            <h6>DESCRIPTION</h6>
                        </div>
                    </div>
                </ng-template>

                <ng-template
                    ng-option-tmp
                    *ngIf="bindLabel2"
                    let-item="item"

                >
                    <div class="row">
                        <div [attr.title]="''" class="col-md-auto custom--select__dataflow">
                            {{ item[bindLabel] }}
                        </div>
                        <div [attr.title]="isTitle?item[bindLabel2]:''" class="col custom--select__dataflow">
                            {{ item[bindLabel2] }}
                        </div>
                    </div>
                </ng-template>



                <ng-template *ngIf="hasbindlabelMr" ng-header-tmp>
                    <div class="row padding_0_1_rem">
                        <div class="custom--select__dataflow width_10rem" >
                            <h6>SERVICE LEVEL</h6>
                        </div>
                        <div class="custom--select__dataflow width_14rem">
                            <h6>STREET1</h6>
                        </div>
                        <div class="custom--select__dataflow width_10rem">
                            <h6>CITY</h6>
                        </div>
                        <div class="custom--select__dataflow width_6rem">
                            <h6>STATE</h6>
                        </div>
                        <div class="custom--select__dataflow width_6rem">
                            <h6>ZIP</h6>
                        </div>
                        <div class="custom--select__dataflow width_6rem">
                            <h6>PHONE</h6>
                        </div>
                        <div class="custom--select__dataflow width_10rem">
                            <h6>SPI NO.</h6>
                        </div>
                        <div class="custom--select__dataflow width_7rem">
                            <h6>ISPRIMARY</h6>
                        </div>
                    </div>
                </ng-template>

                <ng-template
                    ng-option-tmp
                    *ngIf="hasbindlabelMr"
                    let-item="item"

                >
                    <div class="row padding_0_1_rem">
                        <div class="custom--select__dataflow width_10rem" title="{{ item['ServiceLevelName'] }}">
                            {{ item["ServiceLevelName"] }}
                        </div>
                        <div class="custom--select__dataflow width_14rem" >
                            {{ item["AddressLine1"] }}
                        </div>
                        <div class="custom--select__dataflow width_10rem">
                            {{ item["CityName"] }}
                        </div>
                        <div class="custom--select__dataflow width_6rem">
                            {{ item["StateName"] }}
                        </div>
                        <div class="custom--select__dataflow width_6rem">
                            {{ item["ZipCode"] }}
                        </div>
                        <div class="custom--select__dataflow width_6rem">
                            {{ item["Phone"] }}
                        </div>
                        <div class="custom--select__dataflow width_10rem">
                            {{ item["SPINo"] }}
                        </div>
                        <div class="custom--select__dataflow width_7rem">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                [id]="'Ingred' + item['SPINo']"
                                [(ngModel)]="item.IsPrimary"
                            />
                            <label
                                class="form-check-label"
                                [for]="'Ingred' + item['SPINo']"
                            >
                            </label>
                        </div>
                    </div>
                </ng-template>
                <ng-template ng-label-tmp *ngIf="showDefaultValue" let-item="item">
                        {{ inputValue }}
                </ng-template>
                <ng-template ng-label-tmp *ngIf="show2values" let-item="item">
                    {{ item[bindLabel] }} {{ item[bindLabel2] }}
                </ng-template>
                <ng-template ng-label-tmp *ngIf="show3values" let-item="item">
                    {{ item[bindLabel] }} &nbsp;&nbsp; {{ item[bindLabel2] }} &nbsp;&nbsp; {{ item[bindlabel3] }}
                </ng-template>
                <ng-template ng-label-tmp *ngIf="showlabel2" let-item="item">
                    {{ item[bindLabel2] }}
                </ng-template>
                <ng-template ng-label-tmp *ngIf="showlabel3" let-item="item">
                    {{ item[bindlabel3] }}
                </ng-template>
                <!-- <ng-template ng-option-tmp *ngIf="hasbindlabelMr" let-item="item" let-item="index">

                    <table class="table table-striped table-bordered table-sm" style="width: 100%">
                        <thead>
                            <tr style="background: $primary">
                                <th scope="col-1d">
                                    Service Level
                                </th>
                                <th scope="col-1d">
                                    Street1
                                </th>
                                <th scope="col-1d">
                                    city
                                </th>
                                <th scope="col-1d">
                                    state
                                </th>
                                <th scope="col">
                                    zip
                                </th>
                                <th scope="col">
                                    phone
                                </th>
                                <th scope="col">
                                    spino
                                </th>
                                <th scope="col">
                                    isPrimary
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let val of BindValueList; let k=index">
                                <td>{{val.ServiceLevelName}}</td>
                                <td>{{val.AddressLine1}}</td>
                                <td>{{val.CityName}}</td>
                                <td>{{val.StateName}}</td>
                                <td>
                                    {{val.ZipCode}}
                                </td>
                                <td>{{val.Phone}}
                                </td>
                                <td>{{val.SPINo}}</td>
                                <td style="text-align: center">
                                    <input class="form-check-input" type="checkbox" [id]="'Ingred'+k" [(ngModel)]="BindValueList.IsPrimary">
                                    <label class="form-check-label" [for]="'Ingred'+k">
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-template> -->
            </ng-select>
            </fieldset>
        </span>
        <span *ngIf="isInputElemHidden">
            <div class="label--data">
                <span *ngIf="(inputFormGroup?.controls)[custContrlName]?.value">
                    <span *ngFor="let list of filterdList">
                        <span
                            *ngIf="
                                list[bindValue] ==
                                (inputFormGroup?.controls)[custContrlName]?.value
                            "
                        >
                            {{ list[bindLabel] }}</span
                        >
                    </span>
                </span>
                <span *ngIf="!(inputFormGroup?.controls)[custContrlName]?.value">
                    --
                </span>
            </div>
        </span>
    </span>

    <span class="help-block" *ngIf="errorMessage">
        {{ errorMessage }}
    </span>
</div>

<ng-template #Warning let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closAuthWarn();d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Max Refills Allowed is
        {{ &nbsp; dropdownList?.length ? dropdownList?.length - 1 :  0 }}
    </div>
    <div class="modal-footer">
        <button id="btnOK" autofocus footer type="submit" class="hotkey_primary" (click)="closAuthWarn();c('Close click')" (keydown.enter)="closAuthWarn();c('Close click')"
            appShortcutKey InputKey="o">
            <b>O</b>Ok
        </button>
    </div>
</ng-template>
