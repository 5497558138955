import { Component, Input, OnInit } from '@angular/core';
import { MsgConfig } from 'src/app/app.messages';
import { NotesHeaders } from 'src/app/models/labels.models';

@Component({
  selector: 'app-notes-details',
  templateUrl: './notes-details.component.html',
  styleUrls: ['./notes-details.component.scss']
})
export class NotesDetailsComponent implements OnInit {
msgConfig;
notesLabels: any;
requestedNotesInfo: any;
@Input()
set RequestedInfo(infoData: any) {
   if(infoData?.Note){
      this.requestedNotesInfo = infoData.Note;
   }
}
  constructor() { 
   this.msgConfig = MsgConfig;
      this.notesLabels = new NotesHeaders();

  }

  ngOnInit(): void {
  }

}
