import { Component, OnInit, HostListener } from "@angular/core";
import { environment } from "../../../environments/environment";
import { UserService, AlertService, LoaderService, CommonService, BaseHttpInterceptor } from "../../services";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MsgConfig } from "src/app/app.messages";
import { BaseUtils } from "src/app/utils/base.utils";
import * as moment from "moment";
import * as signalR from '@microsoft/signalr';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonWarnorconfirmPopupComponent } from "src/app/modules/shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import * as wjcCore from "@grapecity/wijmo";
import { PrivMaskGuard } from "src/app/guards";
import { AccCategoryE, AccountSettings1E, AccountSettingsE } from "src/app/models";
import { CommonUtil } from "src/app/utils";
import { HubService } from "src/app/services/Hub.service";
import { datadogRum } from '@datadog/browser-rum'
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styles: [`
    :host >>> .alert-custom {
      color: white;
      background-color: #ff4f4f;
      border-color: #9c0901;
    }
  `]
})
export class LoginComponent implements OnInit {
    popupWindow: Window;
    elem: any;
    imgSource: string;
    LoginFrmGrp: FormGroup;
    loggedIn = false;
    excludeColumns: string[] = [
        "token_type",
        "expires_in",
        ".issued",
        ".expires",
        "Privs",
        "SfaxEncryptionVector"
    ];
    pharName: string;
    pharmacyID: number;
    isLoaderPresent: any;
    errorMessage: string;
    isLoggedMultipleUser: boolean = false;
    formSubmitted = false;
    wijimoDistKey: any;
    private connection: signalR.HubConnection;
    TimeOutSession: string;
    loginResp: boolean;
    @HostListener("window:unload", ["$event"])
    unloadBrowser($event: any) {
        if (environment.production) {
            this.closeAfterSessionTimeOut();
            if (this.popupWindow && !this.popupWindow?.closed && !this._userServ.getAccessToken()) {
                this.popupWindow.close();
            }   
        }
    }

    @HostListener("window:beforeunload", ["$event"])
    closeBrowser($event: any) {





    }


    @HostListener('window:storage', ['$event'])
    onStorageChange(ev: StorageEvent) {
        if (!this._userServ.getAccessToken()) {
            this.loggedIn = false;
            this.TimeOutSession = localStorage.getItem("TimeOutSession")
        }
    }
    accountSettingsTabPrivs: any = [];
    accountSettings: any ;

    constructor(
        private _userServ: UserService,
        private _router: Router,
        private _fb: FormBuilder,
        private _commonService: CommonService,
        private _baseUtils: BaseUtils,
        private _actvRoute: ActivatedRoute,
        private loaderService: LoaderService,
        private modalService: NgbModal,
        private _prvMskGrd: PrivMaskGuard,
        private _alertService: AlertService,
        public _commonUtil: CommonUtil,
        public _hubService : HubService, public _httpInt: BaseHttpInterceptor
    ) {

        this.LoginFrmGrp = this._fb.group({
            PharmacyId: [null, Validators.required],
            PharmUsername: [null, Validators.required],
            PharmPassword: [null, Validators.required],
            TechUsername: [],
            TechPassword: [],
            UserId: [],
            CatName: [],
            Priv: [],
            Tenant_Id: [],
            logsRequired:[1]
        });
        this._actvRoute.params.subscribe(
            params => {
                this.pharmacyID = +params["pharmacyID"];
                if (this.pharmacyID) {
                    this.LoginFrmGrp.controls.PharmacyId.patchValue(this.pharmacyID);
                }
            }
        );
    }

    ngOnInit() {
    this.elem = document.documentElement;
        this.imgSource = environment.ImageUrl;

         if (this._userServ.getAccessToken()) {
            this.routingAfterLogin();
        }
        this.loaderService
            .getMessage()
            .subscribe(loader =>
                this.isLoaderPresent = loader);
        // this.loaderService.enableSignIn$.subscribe(respLogin => {
        //         this.disableLogin = respLogin
        // })
    }
    setDatadog() {
         let username= this._commonService.DecryptData(localStorage.getItem("PhUserName"))
        let npi = this._commonService.DecryptData(localStorage.getItem("UniquePharmacyId"))
        datadogRum.onReady(function() {
            datadogRum.setUser({
                 user: username,
                npi: npi
              });
        })
    }
    routingAfterLogin(isFrmLogin: boolean = false): void {
        let timeOut = this._httpInt.checkAndSetTimeout();
        let endURL = "/login";
        const authenticationToken = this._userServ.getAccessToken();
        if (this.popupWindow && !this.popupWindow.closed) {
            this.popupWindow.close();
        }
        //  else {
        if (authenticationToken && !timeOut) {
            endURL = "eprx/dashboard";
            // this.initiateFullScreenMode();
            this.loggedIn = true;
            this.setDatadog();
            /*var sessionStorage = window.sessionStorage.getItem("userLogged");
            if(!sessionStorage) {
                this.isLoggedMultipleUser = true;
                this.displayMessage();
            }  else {
                
            } */
            this.openApplicationWindow(isFrmLogin);

        } else {
            this.loggedIn = false;
            this._router.navigate([endURL]);
        }
    // }
    }
    openApplicationWindow(isFrmLogin: boolean = false) {
         this.popupWindow=window.open(window.location.origin + "/#/eprx/dashboard", "ePrimeRx", "fullscreen=yes,resizable=no,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,top=0,left=0,height="+screen.height+",width="+screen.width);
        if (this.popupWindow) {
            this.popupWindow.moveTo(0, 0);
            this.popupWindow.resizeTo(screen.width, screen.height);
        }
        if(isFrmLogin)
              localStorage.setItem("IsFromAfterlLogin", isFrmLogin+"");
    }
    getWijimoAndDWTDWTLicenseKey() {
        this._userServ.getWijimoAndDWTDWTLicenseKey().subscribe(resp => {
            if (resp) {
                this.wijimoDistKey =  resp.find(obj => obj.KeyName === "wijmoDistrKey");
                const Fontawesome =
                 resp.find(obj => obj.KeyName === "FONTAWESOME_NPM_AUTH_TOKEN");
                const DistKeyVal = this._commonService.DecryptData(this.wijimoDistKey.KeyValue);
                wjcCore.setLicenseKey(
                    DistKeyVal
                );
                const DWTLicenseKey = resp.find(obj => obj.KeyName === "DWTLicenseKey");
                if (DWTLicenseKey) {
                    localStorage.setItem("DWTLicensekey", DWTLicenseKey.KeyValue);
                }
                environment.FONTAWESOME_NPM_AUTH_TOKEN = Fontawesome;
            }
        });
    }
    login(e?: any) {
        if (this.LoginFrmGrp.valid && !this.isLoaderPresent) {
            if (e && (e.which === 13 || e.which === 1)) {
                e.target.blur();
            }
            this.errorMessage = null;
            this._userServ.Login(this.LoginFrmGrp.value).subscribe(
                resp => {
                    if (resp) {
                        if (resp.access_token) {
                            localStorage.setItem("SfaxEncryptionVector" , resp["SfaxEncryptionVector"]);
                            for (const key in resp) {
                                if (
                                    !this.excludeColumns.includes(key) &&
                                    resp.hasOwnProperty(key)
                                ) {
                                    if (key === 'access_token') {
                                        this.setCookie(key, resp[key], 0); // Set access_token in cookie
                                    } else {
                                        this.setLocalStorageData(key, resp[key]); // Set other data in local storage
                                    }
                                }
                            }
                            // resp["Privs"] = this._commonService.encryptData(resp["Privs"]);
                            localStorage.setItem("userLogged", "true");
                            localStorage.setItem("Privs", this._commonService.encryptData(resp["Privs"]));
                            // doubt on this line
                            localStorage.setItem("ExpireTime", this._commonService.encryptData(moment().add(15 * 60, 'seconds').toISOString()));
                            this.LoginFrmGrp.reset();
                            this.AddToGroup();
                            if (this.pharmacyID) {
                                this.LoginFrmGrp.controls.PharmacyId.patchValue(this.pharmacyID);
                            }
                            if (this._userServ.getAccessToken()) {
                                this.checkActiveTabId();
                                this.routingAfterLogin(true);
                                this.errorMessage = null;
                            }
                            // open("location", "_self").close();
                            // window.open(
                            //     window.location.href,
                            //     "",
                            //     "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no"
                            // );
                        } else if (resp.ExceptionMessage === "User not Found") {
                            this.errorMessage = MsgConfig.INVALID_NAME_PASSW;

                            // this._alertService.error(
                            //     MsgConfig.INVALID_NAME_PASSW
                            // );
                        }
                        // if (this._userServ.getAccessToken()) { // commented as the calling of function already there in 224 line
                        //     this.routingAfterLogin(true);
                        // }
                    } else if (resp.ExceptionMessage === "User not Found") {
                        // this._alertService.error(MsgConfig.INdasVALID_NAME_PASSW);
                        this.errorMessage = MsgConfig.INVALID_NAME_PASSW;
                    }
                },
                error => {
                    if (error) {
                        if (error.error && error.error.ExceptionMessage) {
                            if (error.error.ExceptionMessage === "Pharmacist User Locked") {
                                this.errorMessage = MsgConfig.PHARMICIST_ACCOUNT_LOCKED;
                                // this._alertService.error(MsgConfig.PHARMICIST_ACCOUNT_LOCKED);
                            } else if (error.error.ExceptionMessage === "Technician User Locked") {
                                this.errorMessage = MsgConfig.TECHNICIAN_ACCOUNT_LOCKED;

                                // this._alertService.error(MsgConfig.TECHNICIAN_ACCOUNT_LOCKED);
                            } else {
                                this.errorMessage = MsgConfig.INVALID_NAME_PASSW;

                                // this._alertService.error(MsgConfig.INVALID_NAME_PASSW);
                            }
                        } else {
                            this.errorMessage = "Please enter valid credentials.";

                            // this._alertService.error("Please enter valid credentials." );
                        }
                    } else {
                        this.errorMessage = "Please enter valid credentials.";

                        // this._alertService.error("Please enter valid credentials.");
                    }
                }
            );
        } else {
            if (!this.LoginFrmGrp.controls.PharmacyId.valid) {
                // this._alertService.error("Please enter NPI#.");
                this.errorMessage = "Please enter NPI#.";

            } else if ( (this.LoginFrmGrp.controls.PharmUsername.invalid || this.LoginFrmGrp.controls.PharmPassword.invalid ) 
            && !this.isLoaderPresent) {
                // this._alertService.error("Please enter Pharmacist credentials.");
                this.errorMessage = "Please enter Pharmacist credentials.";
             }
        }
    }

    AddToGroup()
    {   
        let npi = this._userServ.getToken("UniquePharmacyId");
         this._hubService.AddToGroup(npi);
    }

    checkActiveTabId() {
        var accountSettingsTabs = [
            { setting: "PharmacyInfo", activeTabId: "Patient1" , settingData: "TenantSettings"},
            { setting: "Settings", activeTabId: "Patient2", settingData: "PharmacySettings" },
            { setting: "RxSettings", activeTabId: "Patient3", settingData: "RxSettings" },
            // { setting: "PatientSettings", activeTabId: "Patient4", settingData: "PatientSettings" },
            { setting: "ClinicalSettings", activeTabId: "Patient5" , settingData: "ClinicalSettings"},
            { setting: "DrugSettings", activeTabId: "Patient6", settingData: "DrugSettings" },
            { setting: "PriceUpdateSettings", activeTabId: "Patient7" , settingData: "DrugPriceUpdateSettings"},
            { setting: "ErxSettings", activeTabId: "Patient8", settingData: "ERxSettings" },
            { setting: "LabelSettings", activeTabId: "Patient9" , settingData: "LabelSettings"},
            { setting: "MessagingSettings", activeTabId: "Patient10", settingData: "MessagingSettings" },
            { setting: "ControlFileSettings", activeTabId: "Patient11" , settingData: "ControlFileSettings"},
            { setting: "DMSSetings", activeTabId: "Patient12" , settingData: "DmsSettings"},
            { setting: "WorkFlowSettings", activeTabId: "Patient13" , settingData: "WorkFlowSettings"},
            { setting: "ReportSettings", activeTabId: "Patient14"  , settingData: "FaxAndReportsSettings" },
            { setting: "TransmissionSettings", activeTabId: "Patient15", settingData: "TransmissionSettings" },
            { setting: "ThreeFourtyBSettings", activeTabId: "Patient16" , settingData: "ThreeFourtyBSettings"},
            { setting: "InventorySettings", activeTabId: "Patient17" , settingData: "InventorySettings"}];
         var privFirstCheck = 0;
         const userPrivs = this._userServ.getUserPrivsFromCookies();
         this.accountSettingsTabPrivs  = [];
      //   var privMaskValue = this._prvMskGrd.getIdentifyPrivValue();
        this.accountSettings = null;
        for(var i=0; i<accountSettingsTabs.length; i++) {
            if(AccountSettingsE[accountSettingsTabs[i]['setting']] && (accountSettingsTabs[i]['setting'] !== 'ClinicalSettings') && this._prvMskGrd.canActivate(AccCategoryE.AccountSettings, AccountSettingsE[accountSettingsTabs[i]['setting']])) {
                if(!privFirstCheck)  {
                    this.accountSettings =  accountSettingsTabs[i]['activeTabId'];
                         this.accountSettingsTabPrivs.push(accountSettingsTabs[i]['settingData']);
                        
                }   
                 else {
                    this.accountSettingsTabPrivs.push(accountSettingsTabs[i]['settingData']);
                }
                privFirstCheck++;
            }  else if((accountSettingsTabs[i]['setting'] === 'ClinicalSettings') && this._prvMskGrd.canActivate(AccCategoryE.AccountSettings1, AccountSettings1E[accountSettingsTabs[i]['setting']])) {
                  "PatientSettings";
                  if(!this.accountSettings)
                    this.accountSettings = accountSettingsTabs[i]['activeTabId'];
                    this.accountSettingsTabPrivs.push(accountSettingsTabs[i]['settingData']);
            } 
        }
        console.log(this.accountSettingsTabPrivs , "this.accountSettingsTabPriv")
        localStorage.removeItem("AccountSettingActiveTab");
        localStorage.removeItem("AccountSettingTabs");
        localStorage.setItem("AccountSettingActiveTab", this._commonService.encryptData((this.accountSettings)));
        if(this.accountSettingsTabPrivs && this.accountSettingsTabPrivs.length){
        localStorage.setItem("AccountSettingTabs", this._commonService.encryptData(this.accountSettingsTabPrivs));
        }
    }

    setCookie(name: string, value: string, secs: number) {
        let expires = "";
        value = this._commonService.encryptData(value);
        if (secs) {
            let date = new Date();
            date.setTime(date.getTime() + secs);
            expires = "; expires=" + date.toUTCString();
        }
        // document.cookie = name + "=" + (value || "") + expires + "; path=/";
        document.cookie = name + "=" + (value || "") + "" + "; path=/;secure" ;
    }

    setLocalStorageData(name: string, value: string) {
        value = this._commonService.encryptData(value);
        localStorage.setItem(name, value);
    }

    initiateFullScreenMode() {
        const id = document.getElementById("clickFullScreenMode");
        if (this.elem) {
            if (this.elem.mozRequestFullScreen) {
                /* Firefox */
                this.elem.mozRequestFullScreen();
            } else if (this.elem.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.elem.webkitRequestFullscreen();
            } else if (this.elem.msRequestFullscreen) {
                /* IE/Edge */
                this.elem.msRequestFullscreen();
            }
        }
    }

    keytab(event: any, fg?: any, fc?: string) {
        let isFcValid = true;
        if (fg && fg.controls[fc]) {
            isFcValid = fg.controls[fc].valid;
        }
        this._baseUtils.keytab(event, isFcValid);
    }

    closeAfterSessionTimeOut() {       
        this._hubService.RemoveFromGroup();
        if (this._userServ.getToken("UserId")) {
            this._userServ
            .Logout(this._userServ.getToken("UserId"))
            .subscribe(resp => {
            });
            this.clearCookies();
        }
        
    }

    clearCookies() {
        const res = document.cookie;
        const multiple = res.split(";");
        for (let i = 0; i < multiple.length; i++) {
            const key = multiple[i].split("=");
            document.cookie =
                "" +
                key[0] +
                "=; expires= Thu, 21 Aug 1970 20:00:00 UTC; path=/ ";
        }
        let printSet = localStorage.getItem("DefaultLabelPrinter")?this._commonService.DecryptData(localStorage.getItem("DefaultLabelPrinter")):null;
        let  reportPrint = localStorage.getItem("DefaultReportPrinter")?this._commonService.DecryptData(localStorage.getItem("DefaultReportPrinter")):null;
        let IsImmediateModeKey = localStorage.getItem("ImmediateMode") ? this._commonService.getDecryptLocalStorage("ImmediateMode"): null;
        let guidKey = localStorage.getItem("GUIDEyecon")?this._commonService.getDecryptLocalStorage("GUIDEyecon"):null;
        let EyeconIP = localStorage.getItem("EyeconIP")?this._commonService.getDecryptLocalStorage("EyeconIP"):null;
        localStorage.clear();
        if (printSet) {
            localStorage.setItem("DefaultLabelPrinter", this._commonService.encryptData(printSet));
        }
        if (reportPrint) {
            localStorage.setItem("DefaultReportPrinter", this._commonService.encryptData(reportPrint));
        }
        if(IsImmediateModeKey) {
            this._commonService.setEncryptLocalStorage("ImmediateMode", IsImmediateModeKey)
        }
        if (guidKey) {
            this._commonService.setEncryptLocalStorage("GUIDEyecon", guidKey)
        }
        if (EyeconIP) {
            this._commonService.setEncryptLocalStorage("EyeconIP", EyeconIP)
        }
        sessionStorage.clear();
    }
    ngAfterViewInit() {
        if(document.querySelector('body') && document.querySelector('body').classList)
              document.querySelector('body').classList.add('bg-white');
    }
    ngOnDestroy(): void {
        if(document.querySelector('body') && document.querySelector('body').classList)
             document.querySelector('body').classList.remove('bg-white');
    }
    displayMessage() {
        const modelRef = this.modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
        });
        modelRef.componentInstance.warnMsg = "You are already logged in on this browser, if you wish to login with a different user, please use a different browser.";
        modelRef.componentInstance.okButton = true;
        modelRef.componentInstance.IsPopUpClosed.subscribe((resp: any) => {
            modelRef.close();
        });
    }
}
