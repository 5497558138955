import { Component, OnInit, Input, Output, EventEmitter, ElementRef, HostListener, OnDestroy, ViewChild, ChangeDetectorRef } from "@angular/core";
import * as moment from "moment";
import { DrugService, CommonService, AlertService } from "src/app/services";
import { AccCategoryE, EquivalentDrug, KeyCodes, Rx2E } from "src/app/models";
import { FormGroup } from "@angular/forms";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { FormatsUtil } from "src/app/utils/formats.util";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import { DecimalPipe } from '@angular/common';
import { CommonUtil, WijimoUtil } from "src/app/utils";
import { PrivMaskGuard } from "src/app/guards";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
    selector: "app-drug-result-card",
    templateUrl: "./drug-result-card.component.html",
    styles: []
})
export class DrugResultCardComponent implements OnInit, OnDestroy {
    showDrugName: boolean;
    showOnlyImp: boolean;
    unsubscribe$: Subject<void> = new Subject();
    @ViewChild("drugflex") drugflex: wjcGrid.FlexGrid;
    @ViewChild("equflex") equflex: wjcGrid.FlexGrid;    
    @ViewChild("addDrug", { static: true }) addDrug: any;
    @ViewChild("openDrugGrid", { static: true }) openDrugGrid: any;
    @ViewChild('searchButton') searchButton!: ElementRef<HTMLButtonElement>;    
    @Input() IsFrmErx: boolean;
    @Input() DrugSuggestFilterFG: FormGroup;
    @Input() bucketFrmPriceChck;
    @Input() isFrmRx;
    @Input() disableBuck;
    @Input() searchFrmPage;
    @Input() searchTerm: any;
    @Input() allowToAddNewDrug;
    @Input() DrugResultData;
    @Input() IsFromTransferIn;
    bucketName: any = "";   
    drugSugListCopy: any;
    defaultBucketId: any;
    required: boolean;
    modelRef: any;
    drugNameBackup: any;

    @Input() updateDrugSuggFilter;
    @Input() DrugBucketList;

    @Input()
    set RouterURL(ru: string) {
        this.routerURL = ru;
    }

    @Input()
    set IsAllToShow(all: boolean) {
        this.isAllToShow = all;
    }

    @Input()
    set ShowImpValues(val: boolean) {
        this.showOnlyImp = val;
    }

    @Input()
    set ShowOnlyDrugName(drugName: boolean) {
        this.showDrugName = drugName;
    }

    @Input() index: any;

    @Input()
    set IsPopUp(ip: boolean) {
        this.isPopUp = ip;
    }

    @Input()
    set IsScannedDrugNotFound(ip: boolean) {
        this.isScannedDrugNotFound = ip;
    }    

    @Input()
    set SingleBucketMode(val: boolean) {
        this.singleBucketMode = val;
    }

    @Output()
    SelectedDrug = new EventEmitter();

    @Output()
    EmitEqDrugs = new EventEmitter();

    @Output()
    EmitGenDrugs = new EventEmitter();

    @Output()
    EmitCloseEqDrugs = new EventEmitter();

    @Output()
    GetSelectedDrug = new EventEmitter();

    @Output()
    AddNewDrug = new EventEmitter();

    @Output()
    CloseDrugResultModal = new EventEmitter(); 
    
    @Output()
    GetDrugBarcodeInfo = new EventEmitter(); 

    @Output()
    CloseDrugPage = new EventEmitter();

    inputData: any;
    drugSugList: any;
    routerURL: string;
    isAllToShow: boolean;
    todayDate: any;
    drugListwjHeaders: any[];
    drugEquListWjHeaders: any[];
    drugSuggestWJ:CollectionView;
    drugEquListWJ:CollectionView;
    genericEquDrugsList: any[];
    genericbrnadDrugsList: any[];
    totalEquvDrugs: any[];
    EquivType = "Generic Equivalent Drugs";
    isDataExists = false;
    showEquDrugs = false;
    isPopUp = false;
    singleBucketMode = false;
    isScannedDrugNotFound: boolean = false;
    clickTimeout: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if ( !event.shiftKey && event.which === 39) { // right
            if (this.elementRef.nativeElement.parentElement.parentElement.className === "dropdown-item active") {
                // this.getEquivalentDrugs();
                event.preventDefault();
            } else {
                event.preventDefault();
            }
        } else if (event.shiftKey && event.which === 39) {
            if (this.elementRef.nativeElement.parentElement.parentElement.className === "dropdown-item active") {
                // this.getGenericDrugs();
            }
        }
    }

    constructor(private elementRef: ElementRef, private _drugSer: DrugService, private _commonServ: CommonService,
        private _drugBuckUtil: DrugBucketUtil,
        private _buckUtils: DrugBucketUtil, public _formatsUtil:FormatsUtil, private _wijimoUtils: WijimoUtil,private _cdr: ChangeDetectorRef, 
        private _decimalPipe: DecimalPipe, private _alertSer: AlertService, private _privMaskGuard: PrivMaskGuard, private _modalService: NgbModal, private _commonUtils: CommonUtil) {
        this.todayDate = {
            year: moment(Date.now()).year(),
            month: moment(Date.now()).month() + 1,
            day: moment(Date.now()).date()
        };
    }

    ngOnInit() {
        sessionStorage.setItem("fromDrugGrid","true");
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "drugSuggestListWj" || w["WijmoKey"] === "drugEquListWj")) {
                this.patchDefaultValHeaders();
            }
        });
        this.drugNameBackup = this.DrugSuggestFilterFG.value['DrugName']
        this.defaultBucketId = this.DrugSuggestFilterFG.value["BucketId"];
        this.getBucketList(true);
        if(this.DrugResultData?.length) {
            this.drugSugList = this.DrugResultData;
            this.drugSugListCopy = this.DrugResultData;
            this.generateDrugWijGrid(this.drugSugList);
        };
       
    }

    

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("drugSuggestListWj");
        this.drugListwjHeaders = this._wijimoUtils.patchDefHeader("drugSuggestListWj", storedWJ);
        const storedWJ2 = await this._wijimoUtils.getWJSavedData("drugEquListWj");
        this.drugEquListWjHeaders = this._wijimoUtils.patchDefHeader("drugEquListWj", storedWJ2);
    }


    onRightClick(ev) {
        if (this.elementRef.nativeElement.parentElement.parentElement.className === "dropdown-item active") {
            // this.getEquivalentDrugs();
            ev.preventDefault();
        } else {
            ev.preventDefault();
        }
    }

    getEquivalentDrugs() {
        this._drugSer.getEquivalentDrug(this.inputData.id, this.inputData.ndc, false, null, null,
            this.DrugSuggestFilterFG ? this.DrugSuggestFilterFG.value : null
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    // this.EmitEqDrugs.emit(resp);
                } else {
                    this.EmitEqDrugs.emit(null);
                }
            });
    }

    getGenericDrugs() {
        this._drugSer.getGenericDrug({
            drugid: this.inputData.id,
            "therapeutic": this.inputData.therapeutic,
            "therapeuticcode": this.inputData.therapeuticcode,
            "isbranded": this.inputData.isbranded
        }).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    // this.EmitEqDrugs.emit(resp);
                    // this.EmitGenDrugs.emit(resp);
                } else {
                    this.EmitGenDrugs.emit(null);
                }
            });
    }

    getGenericAndBrandEquivalentDrugs(data) {
        this.genericEquDrugsList = []; 
        this.genericbrnadDrugsList = [];
        this.totalEquvDrugs = [];        
        this.showEquDrugs = true;
        this._drugSer.getEquivalentDrug(data.id, (data?.ndc) ? data.ndc : "00069097065" , false, null, null, this.DrugSuggestFilterFG).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp?.length) {
                this.totalEquvDrugs = resp;
                this.totalEquvDrugs.forEach(item => {
                    item["isbranded"] ? this.genericbrnadDrugsList.push(item) : this.genericEquDrugsList.push(item);
                });
                this.isDataExists = true;
                this.getGenericAndBrandEquivalentDrugsWijGrid(this.genericEquDrugsList)
            } else {
                this.isDataExists = false;
                this.drugEquListWJ = new CollectionView(null)
            }
        });
    }

    onRowDoubleClick(grid,e,from) {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout); // Cancel single-click execution
        }
        var hti;
        hti = grid.hitTest(e);
        if (hti && hti.row >=0 && grid.selectedRows && grid.selectedRows.length && grid.selectedRows[0].dataItem && hti.cellType === 1){
            let drug = grid.selectedRows[0].dataItem;
            if (!drug.id) {
                this.DrugSuggestFilterFG.value['DrugName'] = drug.drugName;
                this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                    keyboard: false, backdrop: false, windowClass: "large--content"
                });
            } else {
            if(this.drugSugListCopy?.length && from=="drug" && drug?.id) {
                this.drugSugListCopy.forEach(val => {
                    if(val?.id === drug?.id) 
                    {
                        if(this.IsFromTransferIn && val?.drugtypeid === 2)
                            this._alertSer.error("Compound drugs cannot be marked as transfer-in.");
                        else  this.GetSelectedDrug.emit(val);
                    }
                });
                this.showEquDrugs = false;
                this.generateDrugWijGrid(this.drugSugListCopy);
            } else {
                if(this.totalEquvDrugs?.length && from === "equva" && drug?.id) {
                    this.totalEquvDrugs.forEach(val=> {
                        if(val?.id === drug?.id) { 
                            if(this.IsFromTransferIn && val?.drugtypeid === 2)
                                this._alertSer.error("Compound drugs cannot be marked as transfer-in.");
                            else this.GetSelectedDrug.emit(val);
                        } 
                    })
                }
                this.getEquivTypeValue(this.EquivType)
            }  
        }
    }      
    }
    onEnterClick(grid,from,e) {
        let drug = grid;
        if (!drug.id) {
            this.DrugSuggestFilterFG.value['DrugName'] = drug.drugName;
            this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                keyboard: false, backdrop: false, windowClass: "large--content"
            });
        } else {
            if(this.drugSugListCopy?.length && from === "drug" && drug?.id) {
                this.drugSugListCopy.forEach(val => {
                    if(val?.id === drug?.id) 
                    {
                        if(this.IsFromTransferIn && val?.drugtypeid === 2)
                            this._alertSer.error("Compound drugs cannot be marked as transfer-in.");
                        else  this.GetSelectedDrug.emit(val);
                    }
                });
                this.showEquDrugs = false;
                this.generateDrugWijGrid(this.drugSugListCopy);
                this._cdr.detectChanges(); 
            } else {
                if(this.totalEquvDrugs?.length && from === "equva" && drug?.id) {
                    this.totalEquvDrugs.forEach(val=> {
                        if(val?.id === drug?.id) {     
                            if(this.IsFromTransferIn && val?.drugtypeid === 2 )
                                this._alertSer.error("Compound drugs cannot be marked as transfer-in.");
                            else this.GetSelectedDrug.emit(val);               
                        } 
                    })
                }
                this.getEquivTypeValue(this.EquivType)
            }
        }
        e.preventDefault();
    }
    onRowSingleClick(grid,e) {
        // Need SetTimeout here
        var hti = grid.hitTest(e);
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout); // Prevent single-click execution if double-click occurs
          }
         
        this.clickTimeout = setTimeout(() => {
        if (hti && hti.row >=0 && grid.selectedRows && grid.selectedRows.length && grid.selectedRows[0].dataItem && hti.cellType === 1) {
            this.showEquDrugs = true;
            this.getGenericAndBrandEquivalentDrugs(grid.selectedRows[0].dataItem);
            this.EquivType = "Generic Equivalent Drugs";
            }            
        }, 300); // Adjust delay as needed
    }

    getselectedDrugByCheck(drug): any {
        if (!drug.id) {
            const dataForMMS = {
                NDCCode : drug?.ndc ? drug?.ndc : null
            }
            this._drugSer.addDrugAutomaticallyFrmMMS(dataForMMS).pipe(takeUntil(this.unsubscribe$)).subscribe((resp)=>{
                if(resp && resp.id){
                    this.GetSelectedDrug.emit(resp);
                } else{
                    this.DrugSuggestFilterFG.value['DrugName'] = drug.drugName;
                    this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                        keyboard: false, backdrop: false, windowClass: "large--content"
                    });
                }
           });
        } else {
        if(this.drugSugListCopy?.length) {
            this.drugSugListCopy.forEach(val => {
                    if(drug?.id && val?.id === drug?.id ) {
                        if(this.IsFromTransferIn && val?.drugtypeid === 2)
                            this._alertSer.error("Compound drugs cannot be marked as transfer-in.");
                        else
                        this.GetSelectedDrug.emit(val);
                        sessionStorage.removeItem("fromDrugGrid");
                    }
            });
            this.showEquDrugs = false;
            this.generateDrugWijGrid(this.drugSugListCopy);
            this._cdr.detectChanges();
            if (this.drugflex) {
                this.drugflex.select(-1, -1);
                // this.drugflex.hostElement.blur();
            } 
        }
    }
    } 

    selectEqulentDrugByCheck(drug,type) : any {
        if (!drug.id) {
            this.DrugSuggestFilterFG.value['DrugName'] = drug.drugName;
            this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                keyboard: false, backdrop: false, windowClass: "large--content"
            });
        } else {
        if(this.totalEquvDrugs?.length) {
            this.totalEquvDrugs.forEach(val=> {
                if(val?.id === drug.id) {    
                    if(this.IsFromTransferIn && val?.drugtypeid === 2)
                        this._alertSer.error("Compound drugs cannot be marked as transfer-in.");
                    else                
                     this.GetSelectedDrug.emit(val);
                } 
            })
        }
        this.getEquivTypeValue(type)
    }
    }

    getDrugStatus(drugInfo: any) {
        const days = +this._commonServ.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
        const isDrugDiscontinued = drugInfo["discontdt"] && moment().startOf("day")
            .isAfter(moment.utc(drugInfo["discontdt"]).local().add(days, "days"));
        if (isDrugDiscontinued && drugInfo.ispreferred) {
            return "card drug-card-red drug-card-brown";
        } else if (drugInfo["lastDispensedDate"] && drugInfo.ispreferred) {
            return "card drug-card-green drug-card-brown";
        } else if (isDrugDiscontinued) {
            return "card drug-card-red";
        } else if (drugInfo["lastDispensedDate"]) {
            return "card drug-card-green";
        } else if (drugInfo.ispreferred) {
            return "card drug-card-brown";
        } else {
            return "card";
        }
    }

    checkIsDrugDisc(drugInfo: any) {
        const days = +this._commonServ.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
        const isDrugDiscontinued = drugInfo["discontdt"] && moment().startOf("day")
            .isAfter(moment.utc(drugInfo["discontdt"]).local().add(days, "days"));
        if (isDrugDiscontinued) {
            return "drug-card-red";
        }
    }

    checkIsDrugLastDisp(drugInfo: any) {
        if (drugInfo["lastDispensedDate"]) {
            return "drug-card-green";
        }
    }

    checkIsDrugPref(drugInfo: any) {
        if (drugInfo.ispreferred) {
            return "drug-card-brown";
        }
    }

    checkIsPriceExist(manageInventory: any): boolean {
        if (!this.DrugSuggestFilterFG || !this.DrugSuggestFilterFG.value.BucketId) {
            return false;
        }
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.filter(buck => buck.BucketId === +this.DrugSuggestFilterFG.value.BucketId) || [];
            return isExist.length > 0 ? false : true;
        } else {
            return true;
        }
    }

    getQtyInHand(manageInventory: any): any {
        let defBuckId = null; 
        if (!(this.DrugSuggestFilterFG && this.DrugSuggestFilterFG.value.BucketId) && !this.IsFrmErx) {
            return "0.000";
        }        
        if (this.IsFrmErx) {
            defBuckId = this._buckUtils.getDefaultBucketId();
            if (!defBuckId) return "0.000";
        }        
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.find(buck => buck.BucketId === (this.IsFrmErx ? +defBuckId : +this.DrugSuggestFilterFG?.value?.BucketId)) || null;
            return isExist ? this._decimalPipe.transform(isExist["QtyInHand"], "1.3-3") : "0.000";
        } else {
            return "0.000";
        }
        
    }

    getBucketSpecificPrice(manageInventory: any): any {
        let defBuckId = null;
        if (!(this.DrugSuggestFilterFG && this.DrugSuggestFilterFG.value.BucketId) && !this.IsFrmErx) {
            return "0.000";
        }
        if (this.IsFrmErx) {
            defBuckId = this._buckUtils.getDefaultBucketId();
            if (!defBuckId) {
                return "0.000";
            }
        }
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.find(buck => buck.BucketId ===
                 (this.IsFrmErx ? +defBuckId : +this.DrugSuggestFilterFG.value.BucketId)) || null;
            return isExist && isExist["UnitPriceCost"] ? isExist["UnitPriceCost"].toFixed(3) : "0.000";
        } else {
            return "0.000";
        }
    }

    checkInvPrivs() {
        return this._privMaskGuard.canActivate(AccCategoryE.Rx2, Rx2E.AllowChangingInventoryBucket);
    }

    toggleColorLegend() {
        this._alertSer.showHelpText({RxStatus: false, DrugName: false, Status: false, DrugForGridDrugFile : true,isNotAssociate: true});
    }

    async getBucketList(isFromInit: boolean = false) {
        this.DrugBucketList = isFromInit ? await this._commonServ.bucketList$["source"]["value"] : await this._buckUtils.getDrugBucketList(null);
        if (this.DrugBucketList && this.DrugBucketList.length) {
            this.bucketName = this.DrugBucketList.filter((item: any) => {
                return (item.Id === this.DrugSuggestFilterFG.value["BucketId"]);
            });
        }
    }

    getStatus(drugInfo: any) {
        // if (drugInfo.discontdt && moment(drugInfo.discontdt).isSameOrBefore(this.todayDate) && drugInfo.ispreferred) {
        //     return {color: "#FF5C54", bg: this.checkIsPriceExist(drugInfo.manageinventory) ? 'linear-gradient(to left, transparent 98%, #A8A8A8 2%)' : 'linear-gradient(to left, transparent 98%, #A52A2A 2%)'};
        // } else if (drugInfo["Last Disp"] && drugInfo.ispreferred) {
        //     return {color: "#51A351", bg: this.checkIsPriceExist(drugInfo.manageinventory) ? 'linear-gradient(to left, transparent 98%, #A8A8A8 2%)' : 'linear-gradient(to left, transparent 98%, #A52A2A 2%)'};
        // } else if (drugInfo["Last Disp"]) {
            // return {color: "#51A351", bg: this.checkIsPriceExist(drugInfo.manageinventory) ? 'linear-gradient(to left, transparent 98%, #A8A8A8 2%)' : '#FFF'};
        // } else 
        if (drugInfo.discontdt && moment(drugInfo.discontdt).isSameOrBefore(this.todayDate)) {
            return {color: "#FF5C54", bg: this.checkIsPriceExist(drugInfo.manageinventory) ? 'linear-gradient(to left, transparent 98%, #A8A8A8 2%)' : '#FFF'};
        } else if (drugInfo.ispreferred) {
            return {color: "#000", bg: this.checkIsPriceExist(drugInfo.manageinventory) ? 'linear-gradient(to left, transparent 98%, #A8A8A8 2%)' : 'linear-gradient(to left, transparent 98%,rgb(8, 111, 27) 2%)'};
        } else return {color: "#000", bg: this.checkIsPriceExist(drugInfo.manageinventory) ? 'linear-gradient(to left, transparent 98%, #A8A8A8 2%)' : '#FFF'}
    }
    
    generateDrugWijGrid(SendData) {  
        this.drugSuggestWJ = new CollectionView(SendData?.map((drug: any, i) => {
          const k = {};
          k["Drug Name"] = drug.drugname +' '+ (drug?.strength ? drug?.strength : '') +' '+ (drug?.drugunit ? drug?.drugunit : '') +' '+ (drug?.drugformname ? drug?.drugformname : '');
          k["Drug Class"] = drug.drugclass;
          k["NDC"] = this._formatsUtil.getNDCFormat(drug?.drugcode);
          k["Size"] = drug.qtypack ? this._decimalPipe.transform(drug.qtypack, "1.3-3") : "0.000";
          k["Pck/Unit Cost"] = drug.unitpricecost ? "$" + this._decimalPipe.transform(drug.unitpricecost, "1.3-3") : "$0.000";
          k["Pck/Unit AWP"] = (drug.unitpriceawp ? "$" + this._decimalPipe.transform(drug.unitpriceawp, "1.3-3") : "$0.000") + (" ("+ (drug.awppack ? "$" + this._decimalPipe.transform(drug.awppack, "1.2-2") : "$0.00") +")")
          k["Made By"] = drug.manufacturername;
          k["Qty in Hand"] = this.getQtyInHand(drug.manageinventory);
          k["Last Disp"] = drug.lastDispensedDate ? moment.utc(drug.lastDispensedDate).local().format("MM/DD/YYYY") : null;
          k["D/C Date"] = drug.discontdt ? moment.utc(drug.discontdt).local().format("MM/DD/YYYY") : null;
          k["Drug Type"] = drug.drugtype ? drug.drugtype : null;
          k["Brand Name"] = drug.drugbandname ? (drug.drugbandname).toUpperCase() : "";
          k["Generic Name"] = drug.genericname ? (drug.genericname).toUpperCase() : "";
          k["Brand?"] = drug.isbranded ? "Y" : "N";
          k["Equ.Avl?"] = drug.equivalentAvailability;
          k["Gen.Avl?"] = drug.genericAvailability;
          k["Is Pref?"] = drug.ispreferred ? "Y" : "N";
          k["id"] = drug.id;
          k["drugName"] = drug.drugname;
          k["ispreferred"] = drug.ispreferred;
          k["discontdt"] = drug.discontdt;
          k["manageinventory"] = drug.manageinventory;
          k["therapeutic"] = drug.therapeutic;
          k["therapeuticcode"] =  drug.therapeuticcode;
          k["isbranded"] = drug.isbranded;
          k["isSelected"] = false;
          k["drugName"] = drug.drugname;
          return k;
        })
        );
    }

    Init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.autoSizeRow(0, true);
        flex.columnHeaders.rows[0].height = 30;
        setTimeout(() => {
            if(flex?.rows?.length) {
                flex.select(0,0)
                flex.focus();
                this.getGenericAndBrandEquivalentDrugs(this.drugSugListCopy[0]);
            }
        },200);

        flex?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                    this.onEnterClick(flex.collectionView["_src"][flex.selection.row],'drug',e);
            }
        });
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {            
            cell.style.color = "#000";
        }
        if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
            let colors = this.getStatus(row.dataItem)
            if(colors && colors.bg && colors.color) {
                cell.style.color = colors.color;
                cell.style.background = colors.bg;
                cell.style.paddingLeft = "12px"
                cell.style.fontWeight = "700";
            } else {
                cell.style.paddingLeft = "12px"
                cell.style.fontWeight = "700";
            }
        } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c]._hdr === "Is Pref?") {
            let key = panel.columns[c]._hdr;
            if (row.dataItem[key] === "Y") {
                cell.style.backgroundColor = "#34e534";
                cell.style.color = "#fff";
            } else {
                cell.style.backgroundColor = "";
                cell.style.color = "";
            }
        } else {
            cell.style.color = "#000";
            cell.style.background = "#FFF";
            cell.style.fontWeight = "400";
        }
        if (panel.cellType == wjcGrid.CellType.ColumnHeader && r == 0) {
            cell.style.backgroundColor = "#31afe5";
            cell.style.color = "#FFF";
            cell.style.lineHeight = "24px";
            cell.style.fontWeight = "700"
        }
    }

    getEquivTypeValue(value) {
        if (value) {
            this.EquivType = value;
            this.isDataExists = false;
            if(this.EquivType === "Generic Equivalent Drugs") this.getGenericAndBrandEquivalentDrugsWijGrid(this.genericEquDrugsList)
            else if(this.EquivType === "Brand Equivalent Drugs") this.getGenericAndBrandEquivalentDrugsWijGrid(this.genericbrnadDrugsList)
            this.focusFirstGrid()
        }
    }

    getGenericAndBrandEquivalentDrugsWijGrid(SendData) {
        if(this.totalEquvDrugs.length) {
            this.drugEquListWJ = new CollectionView(SendData.map((drug: any, i) => {
            const k = {};
                k["Drug Name"] = drug.drugname +' '+ (drug?.strength ? drug?.strength : '') +' '+ (drug?.drugunit ? drug?.drugunit : '') +' '+ (drug?.drugformname ? drug?.drugformname : '');
                k["Drug Class"] = drug.drugclass;
                k["NDC"] = this._formatsUtil.getNDCFormat(drug.ndc);
                k["Size"] = drug.qtypack ? this._decimalPipe.transform(drug.qtypack, "1.3-3") : "0.000";
                k["Pck/Unit Cost"] = drug.unitpricecost ? "$" + this._decimalPipe.transform(drug.unitpricecost, "1.3-3") : "$0.000";
                k["Pck/Unit AWP"] = (drug.unitpriceawp ? "$" + this._decimalPipe.transform(drug.unitpriceawp, "1.3-3") : "$0.000") + (" ("+ (drug.awppack ? "$" + this._decimalPipe.transform(drug.awppack, "1.2-2") : "$0.00") +")")
                k["Made By"] = drug.manufacturername;
                k["Qty in Hand"] = this.getQtyInHand(drug.manageinventory);
                k["Last Disp"] = drug.lastDispensedDate ? moment.utc(drug.lastDispensedDate).local().format("MM/DD/YYYY") : null;
                k["D/C Date"] = drug.discontdt ? moment.utc(drug.discontdt).local().format("MM/DD/YYYY") : null;
                k["Drug Type"] = drug.drugtype ? drug.drugtype : null;
                k["Brand Name"] = drug.drugbandname ? (drug.drugbandname).toUpperCase() : "";
                k["Generic Name"] = drug.genericname ? (drug.genericname).toUpperCase() : "";
                k["Brand?"] = drug.isbranded ? "Y" : "N";
                k["Is Pref?"] = drug.ispreferred ? "Y" : "N";
                k["Equ.Avl?"] = drug.equivalentAvailability;
                k["Gen.Avl?"] = drug.genericAvailability;
                k["Inv?"] = drug.isinvmangd ? "Y" : "N";
                k["Is 340B"] = drug.Is340B ? "Y" : "N";
                k["id"] = drug.id;
                k["drugName"] = drug.drugname;
                k["drugcode"] = drug.drugcode ? drug.drugcode : null;
                k["manageinventory"] = drug.manageinventory;               
                k["ispreferred"] = drug.ispreferred;
                k["discontdt"] = drug.discontdt;
                k["isSelected"] = false;
                k["drugName"] = drug.drugname;
            return k;
            })
            );
        } else this.isDataExists = false;
    }

    Initequ(flex: wjcGrid.FlexGrid) {
        flex?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                    this.onEnterClick(flex.collectionView["_src"][flex.selection.row],'equva',e);
            }
        });
    }

    itemFormattertwo = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {            
            cell.style.color = "#000";
            cell.style.backgroundColor = "#FFF";
        }      
        if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
            let colors = this.getStatus(row.dataItem)
            if(colors && colors.bg && colors.color) {
                cell.style.color = colors.color;
                cell.style.background = colors.bg;
                cell.style.paddingLeft = "12px"
                cell.style.fontWeight = "700";
            } else {
                cell.style.paddingLeft = "12px"
                cell.style.fontWeight = "700";
            }           
        } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c]._hdr === "Is Pref?") {
            let key = panel.columns[c]._hdr;
            if (row.dataItem[key] === "Y") {
                cell.style.backgroundColor = "#34e534";
                cell.style.color = "#fff";
            } else {
                cell.style.backgroundColor = "";
                cell.style.color = "";
            }
        } else {
            cell.style.color = "#000";
            cell.style.background = "#FFF";
            cell.style.fontWeight = "400";
        }
        if (panel.cellType == wjcGrid.CellType.ColumnHeader && r == 0) {
            cell.style.backgroundColor = "#EAEAEA";
            cell.style.lineHeight = "24px";
            cell.style.color = "#000";
            cell.style.fontWeight = "700"
        }
    }

    async getDrugBySearch() {
        let drugName = this.DrugSuggestFilterFG.value['DrugName'];
        if(drugName?.length > 2) this.required = false;
        if(drugName && (this.drugNameBackup != drugName)) {
                this.genericEquDrugsList = []; 
                this.genericbrnadDrugsList = [];
                this.totalEquvDrugs = [];        
                this.showEquDrugs = false;
        }
        if (drugName) {
            drugName = drugName ? (drugName + '').toLowerCase().trim() : "";
            if (this._commonUtils.checkNDCFormat(drugName)) {
                drugName = drugName.replace(/-/g, '');
            }
            this.drugSugList = this.drugSugListCopy?.filter((item: any) => {
                item["strength"] = item["strength"] ? ((item["strength"] + '').toLowerCase()) : "";
                item["drugunit"] = item["drugunit"] ? ((item["drugunit"] + '').toLowerCase()) : "";
                item["drugformname"] = item["drugformname"] ? ((item["drugformname"] + '').toLowerCase()) : "";
                item["drugname"] = item["drugname"] ? ((item["drugname"] + '').toLowerCase()) : "";
                item["drugtype"] = item["drugtype"] ? ((item["drugtype"] + '').toLowerCase()) : "";
                item["manufacturername"] = item["manufacturername"] ? ((item["manufacturername"] + '').toLowerCase()) : "";
                item["lastDispensedDate"] = item["lastDispensedDate"] ? ((moment.utc(item.lastDispensedDate).local().format("MM/DD/YYYY") + '').toLowerCase()) : "";
                item["discontdt"] = item["discontdt"] ? ((moment.utc(item.discontdt).local().format("MM/DD/YYYY") + '').toLowerCase()) : "";
                item["drugbandname"] = item["drugbandname"] ? ((item["drugbandname"] + '').toLowerCase()) : "";
                item["genericname"] = item["genericname"] ? ((item["genericname"] + '').toLowerCase()) : "";
                item["ispreferred"] = item["ispreferred"] ? ((item["ispreferred"] + '').toLowerCase()) : "";
                item["equivalentAvailability"] = item["equivalentAvailability"] ? ((item["equivalentAvailability"] + '').toLowerCase()) : "";
                item["genericAvailability"] = item["genericAvailability"] ? ((item["genericAvailability"] + '').toLowerCase()) : "";
                const orginalDrugName = item["drugname"] + " " + item["strength"] + " " + item["drugunit"] + " " + item["drugformname"];
                const qtyinhand = this.getQtyInHand(item.manageinventory);
                const unitcost = item["unitpricecost"] ? item["unitpricecost"].toFixed(3) : "0.000";
                const unitawp = (item["unitpriceawp"] ? "$" + item["unitpriceawp"].toFixed(3) : "0.000") + (" ("+ (item["awppack"] ? "$" + item["awppack"].toFixed(3) : "0.000") +")");
                const size = item["qtypack"] ? item["qtypack"].toFixed(3) : "0.000"; 
                return (item["drugcode"].includes(drugName) || item["strength"].includes(drugName) || item["drugunit"].includes(drugName) || item["drugformname"].includes(drugName) || item["drugname"].includes(drugName) 
                    || orginalDrugName.includes(drugName) || item["drugtype"].includes(drugName) || size.includes(drugName) || unitcost.includes(drugName) || qtyinhand.includes(drugName) || unitawp.includes(drugName)
                    || item["manufacturername"].includes(drugName)  || item["lastDispensedDate"].includes(drugName) || item["discontdt"].includes(drugName) || item["drugbandname"].includes(drugName) || item["genericname"].includes(drugName) 
                    || item["ispreferred"].includes(drugName) || item["equivalentAvailability"].includes(drugName) || item["genericAvailability"].includes(drugName));
            });
            this.generateDrugWijGrid(this.drugSugList);
        } else {  this.generateDrugWijGrid(this.drugSugListCopy); }
    }

    clearFilters() {
        this.focusToFirst()               
        this.DrugSuggestFilterFG.controls['BucketId'].patchValue(this.defaultBucketId);
        this.DrugSuggestFilterFG.controls['DrugName'].patchValue(null);
        this.DrugSuggestFilterFG.controls['OnlyShowDrugsAvailableForBucket'].patchValue(this._drugBuckUtil.getInventoryStngs("Single_Bucket_Mode") ? false :this._drugBuckUtil.getInventoryStngs("Default_Only_Show_Drugs_which_are_added_to_this_Bucket"));
        this.DrugSuggestFilterFG.controls['OnlyDrugsHavingQtyInStock'].patchValue(false);
        this.DrugSuggestFilterFG.controls['Is340B'].patchValue(false);
        this.DrugSuggestFilterFG.controls['ShowNonSplittableEquiDrugs'].patchValue(false);
        this.generateDrugWijGrid(this.drugSugListCopy); 
    }

    clearedBySearchIcon() {
        this.DrugSuggestFilterFG.controls['DrugName'].patchValue(null);
        this.required = false;
        this.generateDrugWijGrid(this.drugSugListCopy);
        this.focusToFirst();
    }

    getGlobalSearchDrugSuggest() {
        if(this.DrugSuggestFilterFG.value['DrugName']?.length > 2) {
            let drugName = this.DrugSuggestFilterFG.get('DrugName').value.trim();
            if (this._commonUtils.checkNDCFormat(drugName)) {
                drugName = drugName.replace(/-/g, '');
            }
            this.DrugSuggestFilterFG.get('DrugName').setValue(drugName);
            let suggData = this._commonServ.getGlobalSearchDrugSuggest(this.DrugSuggestFilterFG.value['DrugName'], this.DrugSuggestFilterFG.value || null);
            this.getDrugSugestData(suggData)
            this.showEquDrugs = false;
            this.required = false;
            this.genericEquDrugsList = []; 
            this.genericbrnadDrugsList = [];
            this.totalEquvDrugs = [];
            this.EquivType = "Generic Equivalent Drugs";
            this.drugEquListWJ = new CollectionView(null)
        } else {
            this.required = true
        }     
    }

    getDrugSugestData(drugObserv: Observable<any>) {
        drugObserv.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
                if (resp && resp?.length) {
                    this.drugSugList = resp;
                    this.drugSugListCopy = resp;
                    this.getGenericAndBrandEquivalentDrugs(this.drugSugListCopy[0]);
                    this.focusFirstGrid();
                    this.generateDrugWijGrid(this.drugSugList);               
                } else {
                    this.modelRef = this._modalService.open(this.addDrug, {centered: true,
                        keyboard: false, backdrop: false, windowClass: "large--content"
                    });
                    this.drugSugList = [];
                    this.drugSugListCopy = [];
                    this.isDataExists = false;
                    this.generateDrugWijGrid(this.drugSugList);
                    this.drugEquListWJ = new CollectionView(null)
                }
        })
    }

    closeModal() {
        if (this.modelRef) {           
            this.modelRef.close();
            this.getEquivTypeValue(this.EquivType);
            this.generateDrugWijGrid(this.drugSugListCopy);
        }
        this.clearFilters();
        this.getEquivTypeValue(this.EquivType)                
    }

    closeDrugModal() {
        this.CloseDrugResultModal.emit(true);
        this.clearFilters();
    }

    focusToFirst(e?) {
        if (document.getElementById("DrugNameMMS")) {
            this._cdr.detectChanges();
            document.getElementById("DrugNameMMS").focus();
            if(e) e.preventDefault();
        }
    }

    addNewDrug(val?) {
        let obj = {searchValue : this.DrugSuggestFilterFG.value["DrugName"],fromMMS:val}
        this.closeModal();
        this.AddNewDrug.emit(obj)
    }

    getBarcodeInfo(e) {
        this.GetDrugBarcodeInfo.emit(e);
    }

    ngOnDestroy() {
        sessionStorage.removeItem("fromDrugGrid");
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    focusFirstGrid(): void {
        // Need SetTimeout
        setTimeout(() => {
            if (this.drugflex && this.drugflex.rows.length > 0) {
            this.drugflex.selection = new wjcGrid.CellRange(this.drugflex.selection._row, 0);
            this.drugflex.focus();
            }
        },100);
    }

    focusSecondGrid(e): void {
        // Need SetTimeout
        if(this.showEquDrugs) {
            setTimeout(() => {
                if (this.equflex && this.equflex.rows.length > 0) {
                this.equflex.selection = new wjcGrid.CellRange(0, 0);
                this.equflex.focus();
                }
            }, 100);
        } else this.focusToFirst(e);
        
    }

}
