

<div class="exprx--common-block erx--body insurance--body">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        Pharmacist Notes
    </div>
    <div
    class="eprx--block__content pt-0" style="height: 198px">
        <div class="row pt-4 mb-1">
            <div class="col-6">
                <label>{{notesLabels.Category}}: </label> &nbsp; {{requestedNotesInfo ? 'eRx' : msgConfig.EMPTY_Data}}
            </div> 
        </div>
        <div class="row">
            <div class="col-6">
                <label>{{notesLabels.SubCategory}}</label>
                <div >{{requestedNotesInfo?.NoteCatId ? requestedNotesInfo?.NoteCatId : msgConfig.EMPTY_Data}}</div>
            </div>
            <div class="col-3">
                <label>{{notesLabels.Popupcodes}}</label>
                <div >{{requestedNotesInfo?.PopupScreenCodes ? (requestedNotesInfo?.PopupScreenCodes | uppercase) : msgConfig.EMPTY_Data}}</div>
            </div>
        </div>
        <div class="row row-space">
            <div class="col-12">
                <label>{{notesLabels.Notes}}</label>
                <div class="label--data"><span>{{requestedNotesInfo?.xNote ?
                        requestedNotesInfo?.xNote : msgConfig.EMPTY_Data}}</span></div>
            </div>
        </div>
    </div>
</div>

