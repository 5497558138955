import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { City, OrganizationRequest, PaginationModel, State } from 'src/app/models';
import { CommonService, ErxService, FilesService, RxTransferService, UserService } from 'src/app/services';
import {
  PharmacyData,
  Pharmacy,
  PharmacyDatas,
  RxTransferData,
  Pharmphone
} from "./../../../models/rxTransfer.model";
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonUtil } from 'src/app/utils';
import { MsgConfig } from 'src/app/app.messages';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferPharmacyComponent } from '../transfer-pharmacy/transfer-pharmacy.component';
@Component({
  selector: 'app-pharmacy-suggest',
  templateUrl: './pharmacy-suggest.component.html',
  styleUrls: ['./pharmacy-suggest.component.scss']
})
export class PharmacySuggestComponent implements OnInit {
  unsubscribe$: Subject<void> = new Subject();
  @Output() selectedPharm = new EventEmitter();
  @Output() CloseEditPharmacy = new EventEmitter();
  msgConfig = MsgConfig;
  paginationModel: PaginationModel = new PaginationModel();
  PharmacyNames: PharmacyDatas = new PharmacyDatas();
  logPhaId: any;
  PharmacyNamesList: any;
  getPharmNanes: any;
  addPharFG: FormGroup;
  pharmcyDet: PharmacyData = new PharmacyData();
  public citySelected = null;
  rxTransferFG: FormGroup;
  public stateSelected = null;
  SuggestData: any = [];
  public searchResults: any[] = [];
  searchTerm : any = "";
  selectedPharmDeatails : any = "";
  debounceTime = 1000;
  searching = false;
  DataResult: any;
  isedit : boolean;
  newPharId: any;
  addPopUp = false;
  @Input() set IsEdit(isEdit: boolean) {
    this.isedit = isEdit;
    if(isEdit) {
        this.editPopUp();
    }
  }
  hideFilterForm: boolean;
  faxFG: FormGroup;
  @Input() frmPatHis: any;
  @ViewChild("searchBoxPharmacyType", { static: true }) searchBoxInsType: ElementRef;
   pharmacies = [
    { PharmName: 'Pharmacy One', Fax: '123-456-7890', NPINum: '1111', Phone: '987-654-3210', AddressLine1: '123 Pharmacy St.' },
    { PharmName: 'Pharmacy Two', Fax: '234-567-8901', NPINum: '2222', Phone: '876-543-2109', AddressLine1: '456 Health Ave.' },
    { PharmName: 'Pharmacy Three', Fax: '345-678-9012', NPINum: '3333', Phone: '765-432-1098', AddressLine1: '789 Wellness Rd.' }
  ];
  constructor(public _commonUtil:CommonUtil,
             private _rxSer: RxTransferService,
             private _eRxService: ErxService,
             private _userService: UserService,
             private _modalService: NgbModal,
             private _fb: FormBuilder,
             private _commonServ: CommonService) {
              this.addPharFG = this._fb.group({
                TransferPharmacies: this._fb.group(new Pharmacy()),
                phone1: null,
                phone2: null,
                Fax: null,
                Phone: this._fb.array([new Pharmphone()])
            });

              }

  ngOnInit(): void {
    if (localStorage.getItem("RestoreAddrData") && this._commonServ.DecryptData(localStorage.getItem("RestoreAddrData")) && this.frmPatHis && this.frmPatHis["Type"] === "Out") {
      this.DataResult = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("RestoreAddrData")));
      this.hideFilterForm = true;
      if(this.DataResult?.TransferPharmacy?.Fax)
          this.faxFG.patchValue({
              fax: this.DataResult?.TransferPharmacy?.Fax
          });
  }
  this.toGetTranferPharListWithServiceAvail();
  }

  inputFormatter = (result: any) => result.PharmName;

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term === "") {
          return [];
        }
        return  this.PharmacyNames?.pharmNames?.filter(function (o) {
          return o.hasOwnProperty(term);
        })
      })
    );
  };
  getPharmacyChange(pharmacy) {
    console.log(pharmacy, "pharmacy");
  }
  selectedPharmacy(selectedPharmacy: any) {
      console.log(selectedPharmacy, "selectedPharmacy");
  }
  selectedPharmacyDetails(pharmacy) {
    this.searchTerm = "";
    this.selectedPharmDeatails = pharmacy;
      this.selectedPharm.emit(pharmacy);
  }
  inputClearedBySearchIcon() {
    this.searchTerm = "";
    this.selectedPharmDeatails = null;
    this.selectedPharm.emit(this.selectedPharmDeatails);
  }

  toGetTranferPharListWithServiceAvail(type?) {
    const modelData = new OrganizationRequest();
      this._eRxService.toGetTranferPharListWithServiceAvail(modelData).subscribe((resp: any) => {
          if(resp?.length) {
              this.PharmacyNamesList = resp;
          } else 
              this.PharmacyNamesList = [];
      });
  }
  getPharmachyNames(type?: any) {
    this._rxSer.getPharmacyNames()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if (resp) {
          this.PharmacyNamesList = resp;
          this.PharmacyNames.pharmNames = this.arrangePharmDet(resp["pharmNames"]);
          this.PharmacyNames.pharmNames  = this.PharmacyNames.pharmNames.filter((item: any) => {return item.NPINum});
          this.PharmacyNames.pharmacistNames = resp["pharmacistNames"];
          if (this._userService.getToken("TechUserName") && type) {
              this.logPhaId = null;
              if (resp["pharmacistNames"] && resp["pharmacistNames"].length) {
                 const d = resp["pharmacistNames"].find(v => v["PharmacistName"] === this._userService.getToken("PhUserName"));
                 this.logPhaId = d ? d["PharmacistId"] : null;
              }
          } else {
              this.logPhaId = this._userService.getToken("UserId") ? +this._userService.getToken("UserId") : null;
          }
      }
    });
  }

  arrangePharmDet(data) {
      let pharmaDetails = [];
      if (data && data["pharmNamesViewModels"] && data["pharmNamesViewModels"].length) {
          pharmaDetails = data["pharmNamesViewModels"].map(v => {
              const k = {};
              k["Id"] = v["Id"];
              k["PharmacyName"] = v["PharmacyName"];
              let phoneDet = null;
              if (data["Phones"] && data["Phones"].length) {
                  phoneDet = data["Phones"].filter(d => d["Id"] === v["Id"]);
              }
              if(data["addresses"] && data["addresses"].length){
                  const addrs = data["addresses"].filter(a=> a["id"] === v["Id"]);
                  k["addresses"] = ( addrs[0]?.add1 ?  addrs[0].add1 + "," + " "
                  + (addrs[0].city ?  addrs[0].city + "," + " " : "")  +
                  (addrs[0].state ? addrs[0].state + "," + " " : "") +
                   (addrs[0].zipcode ? addrs[0].zipcode : "") : "" );
                   k["addresses1"]  = addrs[0]?.add1;
                   k["city"]  = addrs[0]?.city;
                   k["state"]  = addrs[0]?.state;
                   k["zipcode"]  = addrs[0]?.zipcode;
                   k["addresses2"]  = addrs[0]?.add2;
              }
              if (phoneDet && phoneDet.length) {
                  const ph12 = phoneDet.filter(pd => pd["PhoneCatId"] === 3);
                  k["Phone1"] = ph12 && ph12.length ? ph12[0]["Phone"] : null;
                  k["Phone2"] = ph12 && ph12.length > 1 ? ph12[1]["Phone"] : null;
                  const fax = phoneDet.filter(pd => pd["PhoneCatId"] === 4);
                  k["Fax"] = fax && fax.length ? fax[0]["Phone"] : null;
              } else {
                  k["Phone1"] = null;
                  k["Phone2"] = null;
                  k["Fax"] = null;
              }
              k["NPINum"] = v.NPINum ? v.NPINum : null;
              k["IsService"] = v.IsService;
              
              return k;
          });
      }
      return pharmaDetails;
  }
  openAddPopUp() {
    this.addPharFG.controls["TransferPharmacies"].reset();
    this.addPharFG.controls["TransferPharmacies"].markAsUntouched();
    this.addPharFG.controls["phone1"].reset();
    this.addPharFG.controls["phone1"].markAsUntouched();
    this.addPharFG.controls["phone2"].reset();
    this.addPharFG.controls["phone2"].markAsUntouched();
    this.addPharFG.controls["Fax"].reset();
    this.addPharFG.controls["Fax"].markAsUntouched();
    this.citySelected = null;
    this.stateSelected = null;
    this.isedit = false;
    this.addPopUp = true;
    this.openTransferPharmacy();
}
openTransferPharmacy() {
  const modalRef = this._modalService.open(TransferPharmacyComponent, {
      centered: true
  });
  modalRef.componentInstance.PharmacyFG = this.addPharFG;
  modalRef.componentInstance.IsEditMode = this.isedit;
  modalRef.result
      .then(res => {
          if (res.AddValue) {
              this.addNewPharResult(res.AddValue);
          }
          if (res.Editvalue) {
              this.editPharResult(res.Editvalue);
              this.CloseEditPharmacy.emit(false);
          }
          this.toGetTranferPharListWithServiceAvail();
          this.closeCommonPopUp(res.IsPopUpClosed);
      })
      .catch(error => error);
}
closeCommonPopUp(content) {
  this.addPopUp = content;
}

editPharResult(data) {
  if (data) {
      this.toGetTranferPharListWithServiceAvail();
      this.getPharmcyDetails();
  }
}

addNewPharResult(data) {
  this.newPharId = data;
  this.toGetTranferPharListWithServiceAvail();
  this.rxTransferFG.controls["XferPharmIdxId"].patchValue(this.newPharId);
  this.rxTransferFG.controls.XferPharmIdxId.markAsTouched();
  this.getPharmcyDetails();
}

getPharmcyDetails() {

}
  editPopUp() {
    this.citySelected = new City();
    this.pharmcyDet = this.DataResult ? this.DataResult : this.pharmcyDet;
    if (this.pharmcyDet.TransferPharmacy) {
        if (this.pharmcyDet.TransferPharmacy.CityId) {
            this.citySelected.Id = this.pharmcyDet.TransferPharmacy.CityId;
            this.citySelected.Name =  this.pharmcyDet.TransferPharmacy.CityName;
            this.citySelected.StateId = this.pharmcyDet.TransferPharmacy.StateId;
        }
        this.stateSelected = new State();
        if (this.pharmcyDet.TransferPharmacy.StateId) {
            this.stateSelected.Id = this.pharmcyDet.TransferPharmacy.StateId;
            this.stateSelected.Name =  this.pharmcyDet.TransferPharmacy.StateName;
        }
        if (this.pharmcyDet.TransferPharmacy.NPINum) {
            this.pharmcyDet.TransferPharmacy.NPINum = this.pharmcyDet.TransferPharmacy.NPINum;
            //  this.pharmcyDet.TransferPharmacy.NPINum = this.pharmcyDet.TransferPharmacy.NPINum.toString();
        }
    }
    for (const key in this.pharmcyDet) {
        if (key) {
            if (key === "TransferPharmacy" && this.pharmcyDet[key]) {
                this.addPharFG.controls["TransferPharmacies"].patchValue(
                    this.pharmcyDet[key]
                );
            }
            if (key === "Phone") {
                for (const keys in this.pharmcyDet[key] &&
                    this.pharmcyDet[key]) {
                    if (keys) {
                        if (keys === "0") {
                            this.addPharFG.controls["phone1"].patchValue(
                                this.pharmcyDet[key][keys]["Phone"]
                            );
                        } else if (keys === "1") {
                            this.addPharFG.controls["phone2"].patchValue(
                                this.pharmcyDet[key][keys]["Phone"]
                            );
                        } else if (keys === "2") {
                            this.addPharFG.controls["Fax"].patchValue(
                                this.pharmcyDet[key][keys]["Phone"]
                            );
                        }
                    }
                }
            }
        }
    }
    this.addPopUp = true;
    this.openTransferPharmacy();
}
  
}  